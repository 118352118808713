export enum EntityType {
  Post = 'post',
  Event = 'event',
  Question = 'question',
  Answer = 'answer',
  Video = 'lecture',
  User = 'user',
  Comment = 'comment',
  Game = 'game',
  Showcase = 'showcase',
  Company = 'company',
  Favorite = 'favorite',
  ProgramSection = 'program_section',
}

export enum AdminEntityType {
  Meeting = 2,
  Company,
  Game,
  ProgramUser,
  ProgramCompany,
  Ticket,
  SubscriptionPlan,
  SponsorPackage,
}

export interface CheckboxFilterItem {
  id: number
  value: boolean
  label: string
  count?: number
}

export interface GenericItem {
  id: number
  value: boolean
  label: string
}

export interface CatalogItem {
  label: string
  value: number
}
