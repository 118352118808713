import type { ConfigInterface } from '~core/models/config'
import type { Media } from './media'
import { Currency } from './orders'
import { enumToOptions, optionsToMap } from '~/utils/options-utils'
import type { OptionInterface } from '~/components/base/controls/use-simple-input'

export enum EventStatus {
  Draft = 'draft',
  Disabled = 'disabled',
  Open = 'open',
}

export enum EventType {
  Default,
  Course,
}

export enum EventSubtype {
  Conference,
  Connect,
  'C-Level Summit',
  Other,
  Summit,
}

export const EventSubtypeOptionsSorted: OptionInterface[] = [
  { value: EventSubtype.Conference, label: 'Conference' },
  { value: EventSubtype.Summit, label: 'Summit' },
  { value: EventSubtype['C-Level Summit'], label: 'C-Level Summit' },
  { value: EventSubtype.Connect, label: 'Connect' },
  { value: EventSubtype.Other, label: 'Other' },
]

export const EventSubtypeMap = optionsToMap(EventSubtypeOptionsSorted)

export enum EventRegion {
  Europe,
  APAC,
  LATAM,
  MENA,
  NORAM,
  AFRICA,
  MEAST,
  CIS,
}

export const EventRegionOptions = enumToOptions(EventRegion, true)
export const EventRegionMap = optionsToMap(EventRegionOptions)

export enum CourseLevel {
  Junior,
  'Junior+',
  Middle,
  'Middle+',
  Senior,
}

export const CourseLevelOptions = enumToOptions(CourseLevel, true, true)
export const CourseLevelMap = optionsToMap(CourseLevelOptions)

export enum CourseSubject {
  GameDesign,
  Management,
  Programming,
  Other,
}

export const CourseSubjectOptions = enumToOptions(CourseSubject, true, false)
export const CourseSubjectMap = optionsToMap(CourseSubjectOptions)

export interface EventFilterField {
  field: string
  values: {
    count: number
    value: number
  }[]
}

export interface EventFilters {
  event_type: EventFilterField
  region: EventFilterField
  course_level: EventFilterField
  course_subject: EventFilterField
}

export interface EventLite {
  id: number
  slug: string
  name: string
  city: string | null
  country_id: string | null
  tagline: string | null
  custom_language: string | null
  custom_price: string | null
  timezone: string
  logo: Media | Media[] | null
  date_from: string
  date_to: string
  hub_date_from: string | null
  hub_date_to: string | null
  custom_date: string | null
  is_online: boolean
  is_onsite: boolean
  auto_reg: number | null
  active: boolean
  address: string | null
  address_link: string | null
  full_address: string | null // for google events
  is_has_ticket?: boolean
  preview_description: string // for meta
  status: EventStatus
  type: EventType
  request_email: string | null
  regions: EventRegion[] | null
  event_type: EventSubtype | null
  course_level: CourseLevel[] | null
  course_subject: CourseSubject[] | null
}

export interface Event extends EventLite {
  main_currency: Currency
  config: ConfigInterface
  latitude: number | null
  longitude: number | null
  host?: string
  background: Media | Media[] | null
  description: string | null // for google events
  is_show: boolean
}

export const EventsListTypeMap = {
  Past: 'past',
  Upcoming: 'upcoming',
  Me: 'me',
  MePast: 'me/past',
  MeUpcoming: 'me/upcoming',
  Top: 'top',
  MeTop: 'me/top',
} as const

export type EventsListType =
  (typeof EventsListTypeMap)[keyof typeof EventsListTypeMap]

export const EventTypeRoutesMap = {
  [EventType.Default]: 'event-info',
  [EventType.Course]: 'academy',
}

export const EventTypePluralNamesMap = {
  [EventType.Default]: 'events',
  [EventType.Course]: 'courses',
}

export const EventTypeNamesMap = {
  [EventType.Default]: 'event',
  [EventType.Course]: 'course',
}

export const EventsWithoutCalendarButton = ['gamefinder']
