<template>
  <BaseModal
    :is-open="isOpen"
    :title="popupTitle || $t('tickets.about_ticket')"
    size="lg"
    @close="emit('close')"
  >
    <div class="flex flex-col gap-6">
      <div class="marked" v-html="getMarkedTextWithBlankLinks(text)" />
      <div class="ml-auto">
        <BaseButton @click="emit('close')">
          {{ $t('common.ok') }}
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
interface PropsInterface {
  text: string
  packageId: number | null
  isOpen: boolean
  packageCount?: number
  popupTitle?: string
}

defineProps<PropsInterface>()

const emit = defineEmits(['close'])
const { getMarkedTextWithBlankLinks } = useMarkedText()
</script>
