<template>
  <div
    class="rounded-xl overflow-hidden relative flex flex-col shadow-md min-w-[260px] bg-white"
  >
    <div
      class="absolute top-0 left-0 w-full h-full border opacity-20 rounded-xl pointer-events-none"
      :style="{ borderColor: category.color }"
    />
    <div
      :style="{ backgroundColor: category.color }"
      class="text-base px-6 py-2 text-white flex justify-between items-center gap-2 min-h-[3.5rem]"
    >
      <div
        class="font-bold"
        :style="{
          color: category.titleColor,
          ...useEditorFontFamily(category.titleFontFamily),
        }"
      >
        {{ category.title.toUpperCase() }}
      </div>
      <BaseButton
        v-if="category.additionalInfo"
        look="none"
        class="!p-0"
        @click="isAboutModalOpen = true"
      >
        <BaseIcon name="outline_question_mark_circle" class="opacity-50" />
      </BaseButton>
    </div>
    <div class="px-6 py-4 flex flex-col gap-4 text-sm h-full">
      <div
        class="flex flex-col gap-4"
        :style="{
          color: category.textColor,
          ...useEditorFontFamily(category.textFontFamily),
        }"
      >
        <div
          v-if="category.text"
          class="marked"
          v-html="getMarkedTextWithBlankLinks(category.text)"
        />
      </div>
      <div
        v-if="category.packages?.length"
        class="flex flex-col gap-4 flex-1 justify-end pt-4"
      >
        <template v-if="category.packages?.length > 1 && selectedPackageId">
          <BaseRadioGroup
            v-model="selectedPackageId"
            :list-item-class="'text-[15px] font-bold'"
            :options="category.packages"
          />
        </template>
        <div class="flex flex-col min-h-[4.5rem] items-center">
          <div
            class="flex text-2xl font-bold gap-1 justify-center items-center flex-wrap"
          >
            <template
              v-if="
                (rawPrice && price) || !selectedPackageConfig?.freePackageText
              "
            >
              <div>{{ price }}</div>
            </template>
            <template v-else>
              {{ selectedPackageConfig?.freePackageText }}
            </template>
          </div>
          <div
            v-if="category.isShowVat"
            class="text-2xl font-bold text-gray-200 text-center italic"
          >
            {{ $t('tickets.vat') }}
          </div>
          <BaseButton
            v-if="category.additionalInfo"
            look="link"
            class="!font-normal"
            size="sm"
            @click="isAboutModalOpen = true"
          >
            {{
              category.aboutPackageText ||
              $t('tickets.about_ticket').toLowerCase()
            }}
          </BaseButton>
        </div>
        <div class="flex items-center gap-4 justify-center">
          <div
            v-if="maxPackagesCount > 1"
            class="border flex items-center gap-1 rounded-xl h-10 px-3 border-primary-800"
          >
            <BaseButton
              look="none"
              class="!p-1"
              :disabled="selectedPackageCount === 1"
              @click="removePackage"
            >
              -
            </BaseButton>
            <div class="w-5 text-center">{{ selectedPackageCount }}</div>
            <BaseButton
              look="none"
              class="!p-1"
              :disabled="selectedPackageCount === maxPackagesCount"
              @click="addPackage"
            >
              +
            </BaseButton>
          </div>
          <BaseButton
            look="border"
            class="!h-10 hover:bg-primary-800 hover:text-white active:text-white transiton duration-150"
            @click="submit"
          >
            {{ $t('tickets.add_to_cart') }}
          </BaseButton>
        </div>
      </div>
    </div>
    <PackagesCategoryCardModal
      v-if="category.additionalInfo"
      :text="category.additionalInfo"
      :package-id="selectedPackageId"
      :is-open="isAboutModalOpen"
      :popup-title="category.popupTitle"
      :package-count="selectedPackageCount"
      @close="isAboutModalOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import PackagesCategoryCardModal from './PackagesCategoryCardModal.vue'
import { Currency, type PackageDetails } from '~/models/orders'
import { useOrders } from '~/stores/orders'
import { languageMap } from '~/models/locale'

export interface PackageSchema {
  label: string
  value: number
  maxPackagesCount?: number
  freePackageText?: string
}

export interface Category {
  title: string
  text?: string
  color?: string
  textColor?: string
  titleColor?: string
  textFontFamily?: string
  titleFontFamily?: string
  isShowVat?: boolean
  aboutPackageText?: string
  popupTitle?: string
  subscribeButton?: any
  subscriptionActiveButton?: any
  additionalInfo?: string
  packages: PackageSchema[]
}

interface PropsInterface {
  category: Category
  packagesInfo: PackageDetails[]
}

const props = withDefaults(defineProps<PropsInterface>(), {
  category: () => ({
    title: '',
    text: '',
    color: '#0D65D8',
    packages: [],
    isShowVat: true,
  }),
  packagesInfo: () => [],
})

const emits = defineEmits(['update'])
const eventsStore = useEvents()
const { formatPrice } = useOrders()
const { getMarkedTextWithBlankLinks } = useMarkedText()
const i18n = useI18n()
const lang = i18n.locale.value

const isAboutModalOpen = ref(false)
const MAX_PACKAGE_COUNT = 99
const selectedPackageCount = ref<number>(1)
const selectedPackageId = ref<number | null>(null)

watchEffect(() => {
  selectedPackageId.value = props.category.packages[0]?.value || null
})

const selectedPackageInfo = computed(() => {
  return props.packagesInfo.find(
    (packageItem) => packageItem.id === selectedPackageId.value
  )
})

const selectedPackageConfig = computed(() => {
  return props.category.packages.find(
    (packageItem) => packageItem.value === selectedPackageId.value
  )
})

const maxPackagesCount = computed(() => {
  return selectedPackageConfig.value?.maxPackagesCount || MAX_PACKAGE_COUNT
})

const currency = computed(() => {
  return eventsStore.currency
})

const rawPrice = computed(() => {
  return selectedPackageInfo.value
    ? selectedPackageInfo.value[
        currency.value.toLowerCase() as Lowercase<Currency>
      ] || 0
    : 0
})

const locale = computed(() => {
  return currency.value === Currency.RUB ? languageMap.ru : languageMap[lang]
})

const price = computed(() => {
  return selectedPackageInfo.value
    ? formatPrice(
        selectedPackageInfo.value[
          currency.value.toLowerCase() as Lowercase<Currency>
        ] || 0,
        currency.value,
        locale.value
      )
    : ''
})

const addPackage = () => {
  selectedPackageCount.value += 1
}

const removePackage = () => {
  selectedPackageCount.value -= 1
}

const submit = () => {
  emits('update', selectedPackageId.value, selectedPackageCount.value)
  selectedPackageCount.value = 1
}
</script>
