<template>
  <div
    :id="uuid"
    ref="ticketsBlock"
    class="p-6 md:p-8 bg-white rounded-xl gap-4"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
    }"
  >
    <div
      v-if="
        (formData.title || formData.subtitle) &&
        !(formData.hideOnEmbed && isInIframe)
      "
      class="mb-6"
    >
      <h2
        v-if="formData.title"
        class="text-2xl md:text-3xl font-bold mb-1"
        :style="{
          textAlign: formData.titleAlign,
          color: formData.titleColor,
          fontWeight: formData.titleWeight,
          fontStyle: formData.titleStyle,
          fontSize: formData.titleSizePx,
          ...useEditorFontFamily(formData.titleFontFamily),
        }"
      >
        {{ formData.title }}
      </h2>
      <div
        v-if="formData.subtitle"
        class="text-xl"
        :style="{
          textAlign: formData.subtitleAlign || undefined,
          color: formData.subtitleColor || undefined,
          fontWeight: formData.subtitleWeight || undefined,
          fontStyle: formData.subtitleStyle || undefined,
          fontSize: formData.subtitleSizePx || undefined,
          ...useEditorFontFamily(formData.subtitleFontFamily),
        }"
      >
        {{ formData.subtitle }}
      </div>
    </div>
    <div v-if="isTicketsLoading" class="p-24 flex justify-center">
      <BaseSpinner />
    </div>
    <div
      v-else-if="formData.categories"
      class="grid grid-cols-1 gap-4"
      :class="
        isInIframe
          ? 'sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
          : 'md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4'
      "
    >
      <template
        v-for="(category, index) in availableTicketsCategories"
        :key="category.title + index"
      >
        <TicketsCategoryCard
          :category="category"
          :tickets-info="ordersStore.ticketsDetails"
          @update="onUpdate"
        />
      </template>
      <BaseObserve empty @change="onVisibleChange" />
    </div>
    <div class="w-full flex flex-col gap-4">
      <div
        v-if="formData.footerText"
        class="marked mt-6"
        :style="{
          color: formData.footerTextColor,
          ...useEditorFontFamily(formData.footerTextFontFamily),
        }"
        v-html="
          getMarkedTextWithBlankLinks(
            formData.footerText,
            formData.footerTextLinkColor
          )
        "
      />
      <div v-if="formData.footerButtons?.length" class="flex flex-wrap gap-4">
        <template
          v-for="(button, index) in formData.footerButtons"
          :key="index"
        >
          <EditorButton :button="button" />
        </template>
      </div>
    </div>
    <BaseLink
      v-if="ticketsCounter"
      :to="isInIframe ? undefined : cartRoute"
      :href="`https://wnhub.io/cart/${eventSlug}?${ticketsQuery}`"
      target="_blank"
      class="!fixed z-10 right-4 rounded-xl flex items-center justify-center bg-white !p-4 shadow-md group transform duration-150"
      :class="[isCartExpanded && 'scale-125']"
      style="top: calc(5rem + var(--sat))"
      size="none"
      theme="none"
      @click="onClickOnCart"
    >
      <BaseIcon name="outline_ticket_thin" size="xl" class="text-primary-800" />
      <div
        class="absolute -top-2 -right-2 flex items-center justify-center bg-red-500 rounded-full h-5 w-5 text-white text-xs"
      >
        {{ ticketsCounter }}
      </div>
    </BaseLink>
    <div
      v-if="formData.showBanner"
      class="fixed bottom-0 right-0 z-40 transition-all duration-700 max-w-full p-2"
      :class="isBannerVisible ? 'translate-y-0' : 'translate-y-full opacity-0'"
      :style="{
        width: formData.bannerWidth + 'px',
      }"
    >
      <div
        class="bottom-full right-0 pr-2"
        :class="isBannerVisible ? 'absolute' : 'hidden'"
      >
        <button
          look="none"
          size="sm"
          class="rounded-full text-gray-600 bg-white/50 p-1.5 hover:bg-white/90 hover:text-gray-800 transition-all"
          @click="closeBanner"
        >
          <BaseIcon name="outline_x" />
        </button>
      </div>
      <div
        class="flex items-center justify-center overflow-hidden shadow-[0_0px_21px_-4px_rgba(10,10,10,0.3)] cursor-pointer hover:-translate-y-[4px] transition-all"
        :style="{
          color: formData.bannerTextColor,
          backgroundColor: formData.bannerBackgroundColor,
          fontSize: formData.bannerTextSize + 'px',
          fontWeight: formData.bannerTextWeight,
          borderColor: formData.bannerBorderColor,
          borderWidth: formData.bannerBorderWidth + 'px',
          paddingLeft: formData.bannerPaddingLeft + 'px',
          paddingRight: formData.bannerPaddingRight + 'px',
          paddingTop: formData.bannerPaddingVertical + 'px',
          paddingBottom: formData.bannerPaddingVertical + 'px',
          borderRadius: formData.bannerBorderRadius + 'px',
        }"
        @click="onBannerClick"
      >
        <img
          v-if="formData.bannerImgLeft"
          :src="formData.bannerImgLeft"
          class="min-w-0"
          :style="{ maxHeight: formData.bannerImgLeftHeight + 'px' }"
        />
        <div
          v-if="formData.bannerText"
          class="whitespace-pre-wrap break-words shrink"
          :style="{
            paddingLeft: formData.bannerTextPaddingLeft + 'px',
            paddingRight: formData.bannerTextPaddingRight + 'px',
          }"
        >
          {{ formData.bannerText }}
        </div>
        <img
          v-if="formData.bannerImgRight"
          :src="formData.bannerImgRight"
          class="min-w-0"
          :style="{ maxHeight: formData.bannerImgRightHeight + 'px' }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash-es/debounce'
import type { Category } from './TicketsCategoryCard.vue'
import TicketsCategoryCard from './TicketsCategoryCard.vue'
import { useEvents } from '~/stores/events'
import { useOrders } from '~/stores/orders'
import { usePageEditor } from '~/stores/page-editor'
import type { TicketDetails } from '~/models/orders'
import { YandexECommerceEventType } from '~/models/ecommerce-yandex'
import EditorButton from '~/components/page-editor/EditorButton.vue'

const { getMarkedTextWithBlankLinks } = useMarkedText()
const eventSlug = useRoute().params.event as string

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  isInIframe: false,
})

const SCROLL_TRIGGER = 200
const isScrolled = ref(false)
const isBannerClosed = ref(false)
const isTicketsVisible = ref(false)
const intersectionObserver = ref<null | IntersectionObserver>(null)

const availableTicketsCategories = computed(() => {
  return props.formData.categories
    ?.map((category: Category) => {
      // If no tickets are left, remove the entire category, but keep categories that were originally empty (i.e., tickets array is [])
      const tickets = category?.tickets?.filter(
        (item) => ordersStore.ticketsDetailsMap[item.value]?.available
      )
      return {
        ...category,
        tickets:
          category?.tickets.length && tickets?.length === 0 ? null : tickets,
      }
    })
    ?.filter((category: Category) => category.tickets)
})

const isBannerVisible = computed(
  () =>
    props.formData.showBanner &&
    !props.isInIframe &&
    isScrolled.value &&
    !isBannerClosed.value &&
    !isTicketsVisible.value &&
    eventsStore?.event?.slug === eventSlug &&
    !eventsStore?.event?.is_has_ticket &&
    !ticketsCounter.value
)
function closeBanner() {
  isBannerClosed.value = true
}

const onScroll = debounce(() => {
  requestAnimationFrame(() => {
    if (window.scrollY > SCROLL_TRIGGER) {
      isScrolled.value = true
    } else {
      isScrolled.value = false
    }
  })
}, 150)
onMounted(() => {
  document.addEventListener('scroll', onScroll, { passive: true })

  intersectionObserver.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        isTicketsVisible.value = true
      } else {
        isTicketsVisible.value = false
      }
    })
  })

  ticketsBlock.value && intersectionObserver.value.observe(ticketsBlock.value)
})

onUnmounted(() => {
  document.removeEventListener('scroll', onScroll)
  intersectionObserver.value?.disconnect()
})

function onBannerClick() {
  ticketsBlock.value?.scrollIntoView({ behavior: 'smooth' })
}

const pageEditorStore = usePageEditor()

const emit = defineEmits(['loaded'])
const eventsStore = useEvents()
const ordersStore = useOrders()
const {
  getSessionStorageValue,
  setSessionStorageValue,
  removeSessionStorageValue,
} = useSessionStorage()
const {
  $yandexMetrika: {
    sendYandexECommerceEvent,
    getYandexECommerceProductFromTicket,
  },
  $linkedin: { lintrk },
  $facebook: { fbq },
  $gtm: {
    gTagPush,
    sendAddToCartGoogleECommerceEvent,
    getGoogleECommerceItemFromTicket,
    sendViewItemListGoogleECommerceEvent,
  },
} = useNuxtApp()

const { selectedTickets } = storeToRefs(ordersStore)
selectedTickets.value = {}
const isTicketsLoading = ref(false)
const isCartExpanded = ref(false)
const ticketsBlock = ref<HTMLElement | null>(null)

const ticketsCounter = computed(() => {
  return Object.values(selectedTickets.value).reduce(
    (acc, count) => acc + count,
    0
  )
})

const currency = computed(() => {
  return eventsStore.currency
})

onServerPrefetch(async () => {
  isTicketsLoading.value = true
  await eventsStore.fetchEvent(eventSlug, false)
  await ordersStore.fetchTicketListDetails(eventsStore.event?.id)
  isTicketsLoading.value = false
})

onMounted(async () => {
  if (!useNuxtApp().isHydrating) {
    isTicketsLoading.value = true
    await eventsStore.fetchEvent(eventSlug, false)
    await ordersStore.fetchTicketListDetails(eventsStore.event?.id)
    isTicketsLoading.value = false
  }

  emit('loaded', ticketsBlock, 'tickets')

  const savedTickets = getSessionStorageValue('tickets')

  if (savedTickets) {
    if (savedTickets.eventId === eventsStore.event?.id) {
      ordersStore.selectedPackages = {}
      selectedTickets.value = savedTickets.list
    } else {
      removeSessionStorageValue('tickets')
    }
  }
})

const onUpdate = (ticketId: number, quantity: number) => {
  ordersStore.selectedPackages = {}
  removeSessionStorageValue('packages')
  if (selectedTickets.value[ticketId]) {
    selectedTickets.value[ticketId] += quantity
  } else {
    selectedTickets.value[ticketId] = quantity
  }

  isCartExpanded.value = true
  setTimeout(() => {
    isCartExpanded.value = false
  }, 150)

  setSessionStorageValue('tickets', {
    eventId: eventsStore.event?.id,
    list: selectedTickets.value,
  })

  lintrk('track', { conversion_id: 17206282 })
  fbq('track', 'AddToCart')
  gTagPush({ event: 'AddToCart' })
  sendECommerceAddEvent(ticketId, quantity)
}

const onClickOnCart = () => {
  lintrk('track', { conversion_id: 17206306 })
  fbq('track', 'ViewContent')
  gTagPush({ event: 'ViewCartContent' })
  removeSessionStorageValue('tickets')
}

const ticketsQuery = computed(() => {
  return Object.keys(cartRoute.value.query)
    .map((key) => `${key}=${cartRoute.value.query[key]}`)
    .join('&')
})

const cartRoute = computed(() => {
  const ticketsQuery = Object.entries(selectedTickets.value).reduce(
    (acc, [id, count]) => {
      acc[`tickets[${id}]`] = count.toString()
      return acc
    },
    {} as Record<string, string>
  )

  return {
    name: 'cart-event',
    params: {
      event: eventsStore.event?.slug || '',
    },
    query: ticketsQuery,
  }
})

function onVisibleChange(isVisible: boolean): void {
  if (isVisible) {
    sendECommerceImpressionsEvent()
  }
}

let isECommerceImpressionsEventSent = false

function sendECommerceImpressionsEvent() {
  if (isECommerceImpressionsEventSent) {
    return
  }

  isECommerceImpressionsEventSent = true

  const tickets: TicketDetails[] = []

  props.formData.categories.forEach((category: Category) => {
    category.tickets?.forEach((ticketParams) => {
      const ticketId = ticketParams.value

      const ticket = ordersStore.ticketsDetailsMap[ticketId]
      ticket && tickets.push(ticket)
    })
  })

  sendYandexECommerceEvent({
    currencyCode: currency.value,
    [YandexECommerceEventType.Impressions]: tickets.map((ticket, index) =>
      getYandexECommerceProductFromTicket(
        ticket,
        currency.value,
        eventsStore.event,
        1,
        '',
        0,
        index
      )
    ),
  })

  sendViewItemListGoogleECommerceEvent(
    {
      currency: currency.value,
    },
    tickets.map((ticket, index) =>
      getGoogleECommerceItemFromTicket(
        ticket,
        currency.value,
        eventsStore.event,
        1,
        '',
        0,
        index
      )
    )
  )
}

function sendECommerceAddEvent(ticketId: number, quantity: number): void {
  const ticket = ordersStore.ticketsDetailsMap[ticketId]

  if (!ticket) {
    return
  }

  sendYandexECommerceEvent({
    currencyCode: currency.value,
    [YandexECommerceEventType.Add]: {
      products: [
        getYandexECommerceProductFromTicket(
          ticket,
          currency.value,
          eventsStore.event,
          quantity
        ),
      ],
    },
  })

  sendAddToCartGoogleECommerceEvent(currency.value, [
    getGoogleECommerceItemFromTicket(
      ticket,
      currency.value,
      eventsStore.event,
      quantity
    ),
  ])
}
</script>
