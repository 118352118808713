<template>
  <BaseSelect
    v-model="value"
    :options="packageOptions"
    name="package"
    class="w-full"
  />
</template>

<script setup lang="ts">
import { useOrders } from '~/stores/orders'
import { useEvents } from '~/stores/events'

const props = defineProps({
  modelValue: {
    type: Number,
    default: undefined,
  },
})

const emit = defineEmits(['update:modelValue'])
const eventsStore = useEvents()
const ordersStore = useOrders()

const packageOptions = computed(() => {
  return ordersStore.packagesDetails.map((packageItem) => ({
    label: packageItem.name,
    value: packageItem.id,
  }))
})

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})

onMounted(async () => {
  await ordersStore.fetchPackageListDetails(eventsStore.event?.id)
})
</script>
