import { defineStore } from 'pinia'
import { FetchError } from 'ofetch'
import { useI18n } from 'vue-i18n'
import { useAuth } from './auth'
import { useGames } from './games'
import { useOrders } from './orders'
import type { UserProfile } from '~/models/users'

interface UserState {
  user: UserProfile | null
}

export const useUsers = defineStore('users', () => {
  const authStore = useAuth()
  const runtimeConfig = useRuntimeConfig()
  const ordersStore = useOrders()
  const gamesStore = useGames()
  const router = useRouter()
  const { t } = useI18n()
  const baseURL = runtimeConfig.public.gatewayApi

  const state = reactive<UserState>({
    user: null,
  })

  const reloadUser = async () => {
    if (!state.user) {
      return
    }
    const response = await useAuthFetch<{ data: UserProfile }>(
      `/users/${state.user.id}`,
      {
        baseURL,
      }
    )
    state.user = response.data
  }

  const fetchUser = async (id?: number) => {
    if (
      state.user &&
      (state.user.id === id ||
        (id === undefined && state.user.id === authStore.profile?.id)) // or me loaded
    ) {
      return
    }
    if (id !== undefined && isNaN(Number(id))) {
      throw useCreateScreenError({ message: 'incorrect user id' })
    }

    state.user = null

    try {
      const response = await useAuthFetch<{ data: UserProfile }>(
        `/users/${id ?? 'me'}`,
        {
          baseURL,
        }
      )
      state.user = response.data
    } catch (error: any) {
      if ((error as FetchError).status === 404) {
        throw useCreateScreenError({ message: 'no such user' })
      }
    }
  }

  const getUser = async (id?: number) => {
    try {
      const { data } = await useAuthFetch<{ data: UserProfile }>(
        `/users/${id ?? 'me'}`,
        {
          baseURL,
        }
      )

      return data
    } catch (error) {
      if ((error as FetchError).status !== 404) {
        throw error
      } else {
        return null
      }
    }
  }

  const sidebarTopRoutes = computed(() => {
    if (authStore.auth) {
      const routes = [
        {
          id: 'my_profile',
          local: t('profile.my_profile'),
          icon: 'outline_user',
          route: '/users/me',
          isActive: router.currentRoute.value.fullPath.includes(
            '/users/me/edit/basic'
          ),
        },
        {
          id: 'pro_account',
          local: t('subscriptions.pro'),
          icon: 'solid_lightning_bolt',
          route: '/pro',
          iconClass: 'text-pro',
        },
        {
          id: 'notifications',
          local: t('profile.notifications'),
          icon: 'outline_bell',
          route: '/users/me/notifications',
          isActive: router.currentRoute.value.fullPath.includes(
            '/users/me/notifications'
          ),
        },
        {
          id: 'favorites',
          local: t('profile.favorites'),
          icon: 'outline_bookmark',
          route: '/users/me/favorites',
          isActive: router.currentRoute.value.fullPath.includes(
            '/users/me/favorites'
          ),
        },
        {
          id: 'my_games',
          local: t('menu.my_games'),
          icon: 'outline_gamepad',
          route: gamesStore.getProfileGamesPageLink(),
          isActive: router.currentRoute.value.fullPath.includes('me/games'),
          hidden: true,
        },
        {
          id: 'tickets',
          local: t('menu.my_tickets'),
          icon: 'outline_ticket',
          route: '/users/me/tickets',
          isActive: router.currentRoute.value.fullPath.includes('me/tickets'),
        },
        {
          id: 'orders',
          local: t('tickets.my_orders'),
          icon: 'outline_briefcase',
          route: '/users/me/orders',
          isActive: router.currentRoute.value.fullPath.includes('me/orders'),
          rightIcon: {
            isShow: ordersStore.itemsToActivateCount.order > 0,
            name: 'outline_exclamation_thin',
            class: 'text-pro-700',
          },
        },
        {
          id: 'company',
          local: t('companies.manage_company'),
          icon: 'outline_briefcase',
          route: '/users/me/edit/company-members',
          isActive: router.currentRoute.value.fullPath.includes(
            '/users/me/edit/company-members'
          ),
          hidden: !authStore.profile?.company,
        },
        {
          id: 'payment_history',
          local: t('subscriptions.payment_history'),
          icon: 'outline_cash',
          route: '/users/me/payment-history',
        },
      ]

      return routes.filter((route) => !(route.hidden && !route.isActive))
    }

    return []
  })

  const sidebarBottomRoutes = computed(() => {
    if (authStore.auth) {
      return [
        {
          id: 'settings',
          local: t('profile.settings'),
          icon: 'outline_cog',
          route: '/users/me/edit/settings',
        },
      ]
    }
    return []
  })

  return {
    user: toRef(state, 'user'),
    isMe: computed(() => state.user?.id === authStore.profile?.id),
    fetchUser,
    getUser,
    reloadUser,
    sidebarTopRoutes,
    sidebarBottomRoutes,
  }
})
