import EditorUploadWidget from '~/components/page-editor/widgets/EditorUploadWidget.vue'
import EditorMarkdownWidget from '~/components/page-editor/widgets/EditorMarkdownWidget.vue'
import EditorTicketWidget from '~/components/page-editor/widgets/EditorTicketWidget.vue'
import EditorPackageWidget from '~/components/page-editor/widgets/EditorPackageWidget.vue'
import BaseColorWidget from '~/components/base/controls/BaseColorWidget.vue'
import EditorEventIdWidget from '~/components/page-editor/widgets/EditorEventIdWidget.vue'
import EditorEventWidget from '~/components/page-editor/widgets/EditorEventWidget.vue'
import EditorEmailTemplateWidget from '~/components/page-editor/widgets/EditorEmailTemplateWidget.vue'
import EditorProgramIdWidget from '~/components/page-editor/widgets/EditorProgramIdWidget.vue'
import EditorBlockListWidget from '~/components/page-editor/widgets/EditorBlockListWidget.vue'
import EditorTextCheckboxWidget from '~/components/page-editor/widgets/EditorTextCheckboxWidget.vue'

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp

  if (process.server) {
    return
  }

  const components = {
    EditorUploadWidget,
    EditorMarkdownWidget,
    EditorTicketWidget,
    EditorPackageWidget,
    BaseColorWidget,
    EditorEventIdWidget,
    EditorEventWidget,
    EditorEmailTemplateWidget,
    EditorProgramIdWidget,
    EditorBlockListWidget,
    EditorTextCheckboxWidget,
  }

  Object.entries(components).forEach(([name, component]) => {
    vueApp.component(name, component)
  })
})
