<template>
  <div
    :id="uuid"
    ref="packagesBlock"
    class="p-6 md:p-8 bg-white rounded-xl gap-4"
    :style="{
      'scroll-margin-top': pageEditorStore.scrollMarginTop,
      'background-color': formData.bgColor,
    }"
  >
    <div
      v-if="
        (formData.title || formData.subtitle) &&
        !(formData.hideOnEmbed && isInIframe)
      "
      class="mb-6"
    >
      <h2
        v-if="formData.title"
        class="text-2xl md:text-3xl font-bold mb-1"
        :style="{
          textAlign: formData.titleAlign,
          color: formData.titleColor,
          fontWeight: formData.titleWeight,
          fontStyle: formData.titleStyle,
          fontSize: formData.titleSizePx,
          ...useEditorFontFamily(formData.titleFontFamily),
        }"
      >
        {{ formData.title }}
      </h2>
      <div
        v-if="formData.subtitle"
        class="text-xl"
        :style="{
          textAlign: formData.subtitleAlign || undefined,
          color: formData.subtitleColor || undefined,
          fontWeight: formData.subtitleWeight || undefined,
          fontStyle: formData.subtitleStyle || undefined,
          fontSize: formData.subtitleSizePx || undefined,
          ...useEditorFontFamily(formData.subtitleFontFamily),
        }"
      >
        {{ formData.subtitle }}
      </div>
    </div>
    <div v-if="isPackagesLoading" class="p-24 flex justify-center">
      <BaseSpinner />
    </div>
    <div
      v-else-if="formData.categories"
      class="grid grid-cols-1 gap-4"
      :class="
        isInIframe
          ? 'sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'
          : 'md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4'
      "
    >
      <template
        v-for="(category, index) in availablePackagesCategories"
        :key="category.title + index"
      >
        <PackagesCategoryCard
          :category="category"
          :packages-info="ordersStore.packagesDetails"
          @update="onUpdate"
        />
      </template>
    </div>
    <div class="w-full flex flex-col gap-4">
      <div
        v-if="formData.footerText"
        class="marked mt-6"
        :style="{
          color: formData.footerTextColor,
          ...useEditorFontFamily(formData.footerTextFontFamily),
        }"
        v-html="
          getMarkedTextWithBlankLinks(
            formData.footerText,
            formData.footerTextLinkColor
          )
        "
      />
      <div v-if="formData.footerButtons?.length" class="flex flex-wrap gap-4">
        <template
          v-for="(button, index) in formData.footerButtons"
          :key="index"
        >
          <EditorButton :button="button" />
        </template>
      </div>
    </div>
    <BaseLink
      v-if="packagesCounter"
      :to="isInIframe ? undefined : cartRoute"
      :href="`https://wnhub.io/cart-packages/${eventSlug}?${packagesQuery}`"
      target="_blank"
      class="!fixed z-10 right-4 rounded-xl flex items-center justify-center bg-white !p-4 shadow-md group transform duration-150"
      :class="[isCartExpanded && 'scale-125']"
      style="top: calc(5rem + var(--sat))"
      size="none"
      theme="none"
      @click="onClickOnCart"
    >
      <BaseIcon name="outline_ticket_thin" size="xl" class="text-primary-800" />
      <div
        class="absolute -top-2 -right-2 flex items-center justify-center bg-red-500 rounded-full h-5 w-5 text-white text-xs"
      >
        {{ packagesCounter }}
      </div>
    </BaseLink>
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash-es/debounce'
import PackagesCategoryCard from './PackagesCategoryCard.vue'
import { useEvents } from '~/stores/events'
import { useOrders } from '~/stores/orders'
import { usePageEditor } from '~/stores/page-editor'
import EditorButton from '~/components/page-editor/EditorButton.vue'

const { getMarkedTextWithBlankLinks } = useMarkedText()
const eventSlug = useRoute().params.event as string

interface PropsInterface {
  uuid: string
  formData: any
  isInIframe?: boolean
}

const props = withDefaults(defineProps<PropsInterface>(), {
  isInIframe: false,
})

const SCROLL_TRIGGER = 200
const isScrolled = ref(false)
const isPackagesVisible = ref(false)
const intersectionObserver = ref<null | IntersectionObserver>(null)

const availablePackagesCategories = computed(() => {
  return props.formData.categories
})

const onScroll = debounce(() => {
  requestAnimationFrame(() => {
    if (window.scrollY > SCROLL_TRIGGER) {
      isScrolled.value = true
    } else {
      isScrolled.value = false
    }
  })
}, 150)
onMounted(() => {
  document.addEventListener('scroll', onScroll, { passive: true })

  intersectionObserver.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        isPackagesVisible.value = true
      } else {
        isPackagesVisible.value = false
      }
    })
  })

  packagesBlock.value && intersectionObserver.value.observe(packagesBlock.value)
})

onUnmounted(() => {
  document.removeEventListener('scroll', onScroll)
  intersectionObserver.value?.disconnect()
})

const pageEditorStore = usePageEditor()

const emit = defineEmits(['loaded'])
const eventsStore = useEvents()
const ordersStore = useOrders()
const {
  getSessionStorageValue,
  setSessionStorageValue,
  removeSessionStorageValue,
} = useSessionStorage()
const {
  $linkedin: { lintrk },
  $facebook: { fbq },
  $gtm: { gTagPush },
} = useNuxtApp()

const { selectedPackages } = storeToRefs(ordersStore) // ref<Record<number, number>>({})
selectedPackages.value = {}
const isPackagesLoading = ref(false)
const isCartExpanded = ref(false)
const packagesBlock = ref<HTMLElement | null>(null)

const packagesCounter = computed(() => {
  return Object.values(selectedPackages.value).reduce(
    (acc, count) => acc + count,
    0
  )
})

onServerPrefetch(async () => {
  isPackagesLoading.value = true
  await eventsStore.fetchEvent(eventSlug, false)
  await ordersStore.fetchPackageListDetails(eventsStore.event?.id)
  isPackagesLoading.value = false
})

onMounted(async () => {
  if (!useNuxtApp().isHydrating) {
    isPackagesLoading.value = true
    await eventsStore.fetchEvent(eventSlug, false)
    await ordersStore.fetchPackageListDetails(eventsStore.event?.id)
    isPackagesLoading.value = false
  }

  emit('loaded', packagesBlock, 'packages')

  const savedPackages = getSessionStorageValue('packages')

  if (savedPackages) {
    if (savedPackages.eventId === eventsStore.event?.id) {
      selectedPackages.value = savedPackages.list
      ordersStore.selectedTickets = {}
    } else {
      removeSessionStorageValue('packages')
    }
  }
})

const onUpdate = (packageId: number, quantity: number) => {
  ordersStore.selectedTickets = {}
  removeSessionStorageValue('tickets')
  if (selectedPackages.value[packageId]) {
    selectedPackages.value[packageId] += quantity
  } else {
    selectedPackages.value[packageId] = quantity
  }

  isCartExpanded.value = true
  setTimeout(() => {
    isCartExpanded.value = false
  }, 150)

  setSessionStorageValue('packages', {
    eventId: eventsStore.event?.id,
    list: selectedPackages.value,
  })

  lintrk('track', { conversion_id: 17206282 })
  fbq('track', 'AddToCart')
  gTagPush({ event: 'AddToCart' })
}

const onClickOnCart = () => {
  lintrk('track', { conversion_id: 17206306 })
  fbq('track', 'ViewContent')
  gTagPush({ event: 'ViewCartContent' })
  removeSessionStorageValue('packages')
}

const packagesQuery = computed(() => {
  return Object.keys(cartRoute.value.query)
    .map((key) => `${key}=${cartRoute.value.query[key]}`)
    .join('&')
})

const cartRoute = computed(() => {
  const packagesQuery = Object.entries(selectedPackages.value).reduce(
    (acc, [id, count]) => {
      acc[`packages[${id}]`] = count.toString()
      return acc
    },
    {} as Record<string, string>
  )

  return {
    name: 'packages-cart-event', // packages link
    params: {
      event: eventsStore.event?.slug || '',
    },
    query: packagesQuery,
  }
})
</script>
