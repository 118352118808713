import { markRaw } from 'vue'
import PackagesView from './PackagesView.vue'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  viewComponent: markRaw(PackagesView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    title: 'Choose your package',
    subtitle: 'You can add packages to the cart',
  },
}
