import { default as indexFCOxRvHzckMeta } from "/builds/sites/wnhub-general-client/pages/[url]-page/index.vue?macro=true";
import { default as indextMKJLElIVzMeta } from "/builds/sites/wnhub-general-client/pages/academy/[event]/index.vue?macro=true";
import { default as about97LLlKQi3mMeta } from "/builds/sites/wnhub-general-client/pages/academy/index/about.vue?macro=true";
import { default as businessTSQS4Ba5nVMeta } from "/builds/sites/wnhub-general-client/pages/academy/index/business.vue?macro=true";
import { default as indexvIIQDOO91TMeta } from "/builds/sites/wnhub-general-client/pages/academy/index/index.vue?macro=true";
import { default as myi71dIfp5b7Meta } from "/builds/sites/wnhub-general-client/pages/academy/index/my.vue?macro=true";
import { default as pastwNkzrMG2MPMeta } from "/builds/sites/wnhub-general-client/pages/academy/index/past.vue?macro=true";
import { default as webinarsXmPjzob3bCMeta } from "/builds/sites/wnhub-general-client/pages/academy/index/webinars.vue?macro=true";
import { default as indexUeUqjzPTRjMeta } from "/builds/sites/wnhub-general-client/pages/academy/index.vue?macro=true";
import { default as public_45offerLYlTiwgRYuMeta } from "/builds/sites/wnhub-general-client/pages/academy/public-offer.vue?macro=true";
import { default as indexx7wAFcPOQFMeta } from "/builds/sites/wnhub-general-client/pages/auth/google/index.vue?macro=true";
import { default as _91code_93CTzcha3GaHMeta } from "/builds/sites/wnhub-general-client/pages/auth/magic/[code].vue?macro=true";
import { default as indexBf0gpAyrQLMeta } from "/builds/sites/wnhub-general-client/pages/cart/[event]/index.vue?macro=true";
import { default as _91id_93R2b0a4PfK8Meta } from "/builds/sites/wnhub-general-client/pages/companies/[id].vue?macro=true";
import { default as companiesC8UGzVORd2Meta } from "/builds/sites/wnhub-general-client/pages/companies.vue?macro=true";
import { default as contactsT7Avz26Jg4Meta } from "/builds/sites/wnhub-general-client/pages/contacts.vue?macro=true";
import { default as _91id_93XFWnUkHGH8Meta } from "/builds/sites/wnhub-general-client/pages/event-info/[event]/check-program/[id].vue?macro=true";
import { default as indexqOma72b19BMeta } from "/builds/sites/wnhub-general-client/pages/event-info/[event]/index.vue?macro=true";
import { default as public_45offerEt6MhiNRJgMeta } from "/builds/sites/wnhub-general-client/pages/event-info/[event]/public-offer.vue?macro=true";
import { default as _91slug_93YKXWezcl9iMeta } from "/builds/sites/wnhub-general-client/pages/event-info/[event]/showcase/[slug].vue?macro=true";
import { default as aboutuOA5JEbraEMeta } from "/builds/sites/wnhub-general-client/pages/event-info/index/about.vue?macro=true";
import { default as indexAMsIYRm4yWMeta } from "/builds/sites/wnhub-general-client/pages/event-info/index/index.vue?macro=true";
import { default as myYf7WTaaQdjMeta } from "/builds/sites/wnhub-general-client/pages/event-info/index/my.vue?macro=true";
import { default as pastRCrboUPMmdMeta } from "/builds/sites/wnhub-general-client/pages/event-info/index/past.vue?macro=true";
import { default as indexC47lifGnVYMeta } from "/builds/sites/wnhub-general-client/pages/event-info/index.vue?macro=true";
import { default as _91id_93nwo1ITHx9MMeta } from "/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/blocks/[id].vue?macro=true";
import { default as companies_45buttonCqvRtyl4tHMeta } from "/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-button.vue?macro=true";
import { default as companies_45tableVzoq5VWmDKMeta } from "/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-table.vue?macro=true";
import { default as indexQkzLiatZ3LMeta } from "/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/index.vue?macro=true";
import { default as index_45test_45page6SCyhW5jsUMeta } from "/builds/sites/wnhub-general-client/pages/index-test-page.vue?macro=true";
import { default as indexFmwB729zM7Meta } from "/builds/sites/wnhub-general-client/pages/index.vue?macro=true";
import { default as indexOsmfc3IjbdMeta } from "/builds/sites/wnhub-general-client/pages/marketplace/index/index.vue?macro=true";
import { default as offer_45serviceh12PnkFbmXMeta } from "/builds/sites/wnhub-general-client/pages/marketplace/index/offer-service.vue?macro=true";
import { default as _91productId_93QAYGP7Q62ZMeta } from "/builds/sites/wnhub-general-client/pages/marketplace/index/products/[productId].vue?macro=true";
import { default as indexpR99VCX0nkMeta } from "/builds/sites/wnhub-general-client/pages/marketplace/index.vue?macro=true";
import { default as index8bftAv4JtkMeta } from "/builds/sites/wnhub-general-client/pages/news/[category]/index.vue?macro=true";
import { default as item_45_91id_93yGPN88Q2q1Meta } from "/builds/sites/wnhub-general-client/pages/news/[category]/item-[id].vue?macro=true";
import { default as popularc2hRwihuY3Meta } from "/builds/sites/wnhub-general-client/pages/news/[category]/popular.vue?macro=true";
import { default as premiumE5T9S1DxDHMeta } from "/builds/sites/wnhub-general-client/pages/news/[category]/premium.vue?macro=true";
import { default as _91category_9324I41V4sYiMeta } from "/builds/sites/wnhub-general-client/pages/news/[category].vue?macro=true";
import { default as indexCA62VUbi3xMeta } from "/builds/sites/wnhub-general-client/pages/news/index.vue?macro=true";
import { default as popularZhHeGoyDWIMeta } from "/builds/sites/wnhub-general-client/pages/news/popular.vue?macro=true";
import { default as premiumlv8SRVLlYZMeta } from "/builds/sites/wnhub-general-client/pages/news/premium.vue?macro=true";
import { default as newscWS5WuBcNCMeta } from "/builds/sites/wnhub-general-client/pages/news.vue?macro=true";
import { default as indexFvWmjD1TILMeta } from "/builds/sites/wnhub-general-client/pages/packages-cart/[event]/index.vue?macro=true";
import { default as privacy_45policyPreT20nEW6Meta } from "/builds/sites/wnhub-general-client/pages/privacy-policy.vue?macro=true";
import { default as pro0BKHPgHHJWMeta } from "/builds/sites/wnhub-general-client/pages/pro.vue?macro=true";
import { default as indexzpUTVcJJVkMeta } from "/builds/sites/wnhub-general-client/pages/questions/[category]/index.vue?macro=true";
import { default as item_45_91id_934JBI1qa0MgMeta } from "/builds/sites/wnhub-general-client/pages/questions/[category]/item-[id].vue?macro=true";
import { default as popularu9c8F03KmoMeta } from "/builds/sites/wnhub-general-client/pages/questions/[category]/popular.vue?macro=true";
import { default as _91category_93MyRxgsPjfTMeta } from "/builds/sites/wnhub-general-client/pages/questions/[category].vue?macro=true";
import { default as indexhL04ZDQ82tMeta } from "/builds/sites/wnhub-general-client/pages/questions/index.vue?macro=true";
import { default as popularbzwlaMwNMiMeta } from "/builds/sites/wnhub-general-client/pages/questions/popular.vue?macro=true";
import { default as questions4baAEHAd2jMeta } from "/builds/sites/wnhub-general-client/pages/questions.vue?macro=true";
import { default as talentsJtQk1uDUztMeta } from "/builds/sites/wnhub-general-client/pages/talents.vue?macro=true";
import { default as terms_45conditionsZelWAqjpD9Meta } from "/builds/sites/wnhub-general-client/pages/terms-conditions.vue?macro=true";
import { default as _91id_93OUqbpMOdbNMeta } from "/builds/sites/wnhub-general-client/pages/users/[id].vue?macro=true";
import { default as basicaE4lV5nEhFMeta } from "/builds/sites/wnhub-general-client/pages/users/me/edit/basic.vue?macro=true";
import { default as company_45membersmNN7CPxLWiMeta } from "/builds/sites/wnhub-general-client/pages/users/me/edit/company-members.vue?macro=true";
import { default as settingszMj39xeeQlMeta } from "/builds/sites/wnhub-general-client/pages/users/me/edit/settings.vue?macro=true";
import { default as companiesAUZiN9xmTWMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites/companies.vue?macro=true";
import { default as eventsIHguZlQRmaMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites/events.vue?macro=true";
import { default as indexI47ilk6aIyMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites/index.vue?macro=true";
import { default as lecturesJDRRZeNP9uMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites/lectures.vue?macro=true";
import { default as newsjJNp6IttrFMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites/news.vue?macro=true";
import { default as favoritesUKR4dhyZzQMeta } from "/builds/sites/wnhub-general-client/pages/users/me/favorites.vue?macro=true";
import { default as editKUQzd1816HMeta } from "/builds/sites/wnhub-general-client/pages/users/me/games/[id]/edit.vue?macro=true";
import { default as indexjiKdk1aCPPMeta } from "/builds/sites/wnhub-general-client/pages/users/me/games/[id]/index.vue?macro=true";
import { default as createZsyYnWHfGPMeta } from "/builds/sites/wnhub-general-client/pages/users/me/games/create.vue?macro=true";
import { default as indexcF4TtGp9JJMeta } from "/builds/sites/wnhub-general-client/pages/users/me/games/index.vue?macro=true";
import { default as indexHj1J85Qb0xMeta } from "/builds/sites/wnhub-general-client/pages/users/me/index.vue?macro=true";
import { default as notificationsqzSFiq1tkrMeta } from "/builds/sites/wnhub-general-client/pages/users/me/notifications.vue?macro=true";
import { default as _91id_93EMHUlzVAZPMeta } from "/builds/sites/wnhub-general-client/pages/users/me/orders/[id].vue?macro=true";
import { default as index8zdZ3MMsk4Meta } from "/builds/sites/wnhub-general-client/pages/users/me/orders/index.vue?macro=true";
import { default as payment_45historyMIuOED96zgMeta } from "/builds/sites/wnhub-general-client/pages/users/me/payment-history.vue?macro=true";
import { default as _91id_93JEGGvy13pJMeta } from "/builds/sites/wnhub-general-client/pages/users/me/tickets/[id].vue?macro=true";
import { default as index95nKoleiMZMeta } from "/builds/sites/wnhub-general-client/pages/users/me/tickets/index.vue?macro=true";
import { default as mevstyywGv64Meta } from "/builds/sites/wnhub-general-client/pages/users/me.vue?macro=true";
import { default as usersjYpGzagbSDMeta } from "/builds/sites/wnhub-general-client/pages/users.vue?macro=true";
import { default as indexpAMPyWudvPMeta } from "/builds/sites/wnhub-general-client/pages/video/[category]/index.vue?macro=true";
import { default as item_45_91id_932ihgVr47MyMeta } from "/builds/sites/wnhub-general-client/pages/video/[category]/item-[id].vue?macro=true";
import { default as populartibytBoylVMeta } from "/builds/sites/wnhub-general-client/pages/video/[category]/popular.vue?macro=true";
import { default as _91category_93PTROhxIezuMeta } from "/builds/sites/wnhub-general-client/pages/video/[category].vue?macro=true";
import { default as indexuOQ2Q9c4iaMeta } from "/builds/sites/wnhub-general-client/pages/video/index.vue?macro=true";
import { default as popularFTMxzZXccFMeta } from "/builds/sites/wnhub-general-client/pages/video/popular.vue?macro=true";
import { default as videoubMXtUehlZMeta } from "/builds/sites/wnhub-general-client/pages/video.vue?macro=true";
import { default as wn_45forbes_45magazineQlC5UbxelAMeta } from "/builds/sites/wnhub-general-client/pages/wn-forbes-magazine.vue?macro=true";
import { default as wn_45game_45publishingebXDBak5WbMeta } from "/builds/sites/wnhub-general-client/pages/wn-game-publishing.vue?macro=true";
export default [
  {
    name: indexFCOxRvHzckMeta?.name ?? "url-page___en",
    path: indexFCOxRvHzckMeta?.path ?? "/:url()-page",
    meta: indexFCOxRvHzckMeta || {},
    alias: indexFCOxRvHzckMeta?.alias || [],
    redirect: indexFCOxRvHzckMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/[url]-page/index.vue").then(m => m.default || m)
  },
  {
    name: indexFCOxRvHzckMeta?.name ?? "url-page___ru",
    path: indexFCOxRvHzckMeta?.path ?? "/ru/:url()-page",
    meta: indexFCOxRvHzckMeta || {},
    alias: indexFCOxRvHzckMeta?.alias || [],
    redirect: indexFCOxRvHzckMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/[url]-page/index.vue").then(m => m.default || m)
  },
  {
    name: indexFCOxRvHzckMeta?.name ?? "url-page___zh",
    path: indexFCOxRvHzckMeta?.path ?? "/zh/:url()-page",
    meta: indexFCOxRvHzckMeta || {},
    alias: indexFCOxRvHzckMeta?.alias || [],
    redirect: indexFCOxRvHzckMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/[url]-page/index.vue").then(m => m.default || m)
  },
  {
    name: indextMKJLElIVzMeta?.name ?? "academy-event___en",
    path: indextMKJLElIVzMeta?.path ?? "/academy/:event()",
    meta: indextMKJLElIVzMeta || {},
    alias: indextMKJLElIVzMeta?.alias || [],
    redirect: indextMKJLElIVzMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indextMKJLElIVzMeta?.name ?? "academy-event___ru",
    path: indextMKJLElIVzMeta?.path ?? "/ru/academy/:event()",
    meta: indextMKJLElIVzMeta || {},
    alias: indextMKJLElIVzMeta?.alias || [],
    redirect: indextMKJLElIVzMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indextMKJLElIVzMeta?.name ?? "academy-event___zh",
    path: indextMKJLElIVzMeta?.path ?? "/zh/academy/:event()",
    meta: indextMKJLElIVzMeta || {},
    alias: indextMKJLElIVzMeta?.alias || [],
    redirect: indextMKJLElIVzMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUeUqjzPTRjMeta?.name ?? undefined,
    path: indexUeUqjzPTRjMeta?.path ?? "/academy",
    meta: indexUeUqjzPTRjMeta || {},
    alias: indexUeUqjzPTRjMeta?.alias || [],
    redirect: indexUeUqjzPTRjMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index.vue").then(m => m.default || m),
    children: [
  {
    name: about97LLlKQi3mMeta?.name ?? "academy-index-about___en",
    path: about97LLlKQi3mMeta?.path ?? "about",
    meta: about97LLlKQi3mMeta || {},
    alias: about97LLlKQi3mMeta?.alias || [],
    redirect: about97LLlKQi3mMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/about.vue").then(m => m.default || m)
  },
  {
    name: businessTSQS4Ba5nVMeta?.name ?? "academy-index-business___en",
    path: businessTSQS4Ba5nVMeta?.path ?? "business",
    meta: businessTSQS4Ba5nVMeta || {},
    alias: businessTSQS4Ba5nVMeta?.alias || [],
    redirect: businessTSQS4Ba5nVMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/business.vue").then(m => m.default || m)
  },
  {
    name: indexvIIQDOO91TMeta?.name ?? "academy-index___en",
    path: indexvIIQDOO91TMeta?.path ?? "",
    meta: indexvIIQDOO91TMeta || {},
    alias: indexvIIQDOO91TMeta?.alias || [],
    redirect: indexvIIQDOO91TMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/index.vue").then(m => m.default || m)
  },
  {
    name: myi71dIfp5b7Meta?.name ?? "academy-index-my___en",
    path: myi71dIfp5b7Meta?.path ?? "my",
    meta: myi71dIfp5b7Meta || {},
    alias: myi71dIfp5b7Meta?.alias || [],
    redirect: myi71dIfp5b7Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/my.vue").then(m => m.default || m)
  },
  {
    name: pastwNkzrMG2MPMeta?.name ?? "academy-index-past___en",
    path: pastwNkzrMG2MPMeta?.path ?? "past",
    meta: pastwNkzrMG2MPMeta || {},
    alias: pastwNkzrMG2MPMeta?.alias || [],
    redirect: pastwNkzrMG2MPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/past.vue").then(m => m.default || m)
  },
  {
    name: webinarsXmPjzob3bCMeta?.name ?? "academy-index-webinars___en",
    path: webinarsXmPjzob3bCMeta?.path ?? "webinars",
    meta: webinarsXmPjzob3bCMeta || {},
    alias: webinarsXmPjzob3bCMeta?.alias || [],
    redirect: webinarsXmPjzob3bCMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/webinars.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexUeUqjzPTRjMeta?.name ?? undefined,
    path: indexUeUqjzPTRjMeta?.path ?? "/ru/academy",
    meta: indexUeUqjzPTRjMeta || {},
    alias: indexUeUqjzPTRjMeta?.alias || [],
    redirect: indexUeUqjzPTRjMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index.vue").then(m => m.default || m),
    children: [
  {
    name: about97LLlKQi3mMeta?.name ?? "academy-index-about___ru",
    path: about97LLlKQi3mMeta?.path ?? "about",
    meta: about97LLlKQi3mMeta || {},
    alias: about97LLlKQi3mMeta?.alias || [],
    redirect: about97LLlKQi3mMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/about.vue").then(m => m.default || m)
  },
  {
    name: businessTSQS4Ba5nVMeta?.name ?? "academy-index-business___ru",
    path: businessTSQS4Ba5nVMeta?.path ?? "business",
    meta: businessTSQS4Ba5nVMeta || {},
    alias: businessTSQS4Ba5nVMeta?.alias || [],
    redirect: businessTSQS4Ba5nVMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/business.vue").then(m => m.default || m)
  },
  {
    name: indexvIIQDOO91TMeta?.name ?? "academy-index___ru",
    path: indexvIIQDOO91TMeta?.path ?? "",
    meta: indexvIIQDOO91TMeta || {},
    alias: indexvIIQDOO91TMeta?.alias || [],
    redirect: indexvIIQDOO91TMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/index.vue").then(m => m.default || m)
  },
  {
    name: myi71dIfp5b7Meta?.name ?? "academy-index-my___ru",
    path: myi71dIfp5b7Meta?.path ?? "my",
    meta: myi71dIfp5b7Meta || {},
    alias: myi71dIfp5b7Meta?.alias || [],
    redirect: myi71dIfp5b7Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/my.vue").then(m => m.default || m)
  },
  {
    name: pastwNkzrMG2MPMeta?.name ?? "academy-index-past___ru",
    path: pastwNkzrMG2MPMeta?.path ?? "past",
    meta: pastwNkzrMG2MPMeta || {},
    alias: pastwNkzrMG2MPMeta?.alias || [],
    redirect: pastwNkzrMG2MPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/past.vue").then(m => m.default || m)
  },
  {
    name: webinarsXmPjzob3bCMeta?.name ?? "academy-index-webinars___ru",
    path: webinarsXmPjzob3bCMeta?.path ?? "webinars",
    meta: webinarsXmPjzob3bCMeta || {},
    alias: webinarsXmPjzob3bCMeta?.alias || [],
    redirect: webinarsXmPjzob3bCMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/webinars.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexUeUqjzPTRjMeta?.name ?? undefined,
    path: indexUeUqjzPTRjMeta?.path ?? "/zh/academy",
    meta: indexUeUqjzPTRjMeta || {},
    alias: indexUeUqjzPTRjMeta?.alias || [],
    redirect: indexUeUqjzPTRjMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index.vue").then(m => m.default || m),
    children: [
  {
    name: about97LLlKQi3mMeta?.name ?? "academy-index-about___zh",
    path: about97LLlKQi3mMeta?.path ?? "about",
    meta: about97LLlKQi3mMeta || {},
    alias: about97LLlKQi3mMeta?.alias || [],
    redirect: about97LLlKQi3mMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/about.vue").then(m => m.default || m)
  },
  {
    name: businessTSQS4Ba5nVMeta?.name ?? "academy-index-business___zh",
    path: businessTSQS4Ba5nVMeta?.path ?? "business",
    meta: businessTSQS4Ba5nVMeta || {},
    alias: businessTSQS4Ba5nVMeta?.alias || [],
    redirect: businessTSQS4Ba5nVMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/business.vue").then(m => m.default || m)
  },
  {
    name: indexvIIQDOO91TMeta?.name ?? "academy-index___zh",
    path: indexvIIQDOO91TMeta?.path ?? "",
    meta: indexvIIQDOO91TMeta || {},
    alias: indexvIIQDOO91TMeta?.alias || [],
    redirect: indexvIIQDOO91TMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/index.vue").then(m => m.default || m)
  },
  {
    name: myi71dIfp5b7Meta?.name ?? "academy-index-my___zh",
    path: myi71dIfp5b7Meta?.path ?? "my",
    meta: myi71dIfp5b7Meta || {},
    alias: myi71dIfp5b7Meta?.alias || [],
    redirect: myi71dIfp5b7Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/my.vue").then(m => m.default || m)
  },
  {
    name: pastwNkzrMG2MPMeta?.name ?? "academy-index-past___zh",
    path: pastwNkzrMG2MPMeta?.path ?? "past",
    meta: pastwNkzrMG2MPMeta || {},
    alias: pastwNkzrMG2MPMeta?.alias || [],
    redirect: pastwNkzrMG2MPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/past.vue").then(m => m.default || m)
  },
  {
    name: webinarsXmPjzob3bCMeta?.name ?? "academy-index-webinars___zh",
    path: webinarsXmPjzob3bCMeta?.path ?? "webinars",
    meta: webinarsXmPjzob3bCMeta || {},
    alias: webinarsXmPjzob3bCMeta?.alias || [],
    redirect: webinarsXmPjzob3bCMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/index/webinars.vue").then(m => m.default || m)
  }
]
  },
  {
    name: public_45offerLYlTiwgRYuMeta?.name ?? "academy-public-offer___en",
    path: public_45offerLYlTiwgRYuMeta?.path ?? "/academy/public-offer",
    meta: public_45offerLYlTiwgRYuMeta || {},
    alias: public_45offerLYlTiwgRYuMeta?.alias || [],
    redirect: public_45offerLYlTiwgRYuMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/public-offer.vue").then(m => m.default || m)
  },
  {
    name: public_45offerLYlTiwgRYuMeta?.name ?? "academy-public-offer___ru",
    path: public_45offerLYlTiwgRYuMeta?.path ?? "/ru/academy/public-offer",
    meta: public_45offerLYlTiwgRYuMeta || {},
    alias: public_45offerLYlTiwgRYuMeta?.alias || [],
    redirect: public_45offerLYlTiwgRYuMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/public-offer.vue").then(m => m.default || m)
  },
  {
    name: public_45offerLYlTiwgRYuMeta?.name ?? "academy-public-offer___zh",
    path: public_45offerLYlTiwgRYuMeta?.path ?? "/zh/academy/public-offer",
    meta: public_45offerLYlTiwgRYuMeta || {},
    alias: public_45offerLYlTiwgRYuMeta?.alias || [],
    redirect: public_45offerLYlTiwgRYuMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/academy/public-offer.vue").then(m => m.default || m)
  },
  {
    name: indexx7wAFcPOQFMeta?.name ?? "auth-google___en",
    path: indexx7wAFcPOQFMeta?.path ?? "/auth/google",
    meta: indexx7wAFcPOQFMeta || {},
    alias: indexx7wAFcPOQFMeta?.alias || [],
    redirect: indexx7wAFcPOQFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/auth/google/index.vue").then(m => m.default || m)
  },
  {
    name: indexx7wAFcPOQFMeta?.name ?? "auth-google___ru",
    path: indexx7wAFcPOQFMeta?.path ?? "/ru/auth/google",
    meta: indexx7wAFcPOQFMeta || {},
    alias: indexx7wAFcPOQFMeta?.alias || [],
    redirect: indexx7wAFcPOQFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/auth/google/index.vue").then(m => m.default || m)
  },
  {
    name: indexx7wAFcPOQFMeta?.name ?? "auth-google___zh",
    path: indexx7wAFcPOQFMeta?.path ?? "/zh/auth/google",
    meta: indexx7wAFcPOQFMeta || {},
    alias: indexx7wAFcPOQFMeta?.alias || [],
    redirect: indexx7wAFcPOQFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/auth/google/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93CTzcha3GaHMeta?.name ?? "auth-magic-code___en",
    path: _91code_93CTzcha3GaHMeta?.path ?? "/auth/magic/:code()",
    meta: _91code_93CTzcha3GaHMeta || {},
    alias: _91code_93CTzcha3GaHMeta?.alias || [],
    redirect: _91code_93CTzcha3GaHMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/auth/magic/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93CTzcha3GaHMeta?.name ?? "auth-magic-code___ru",
    path: _91code_93CTzcha3GaHMeta?.path ?? "/ru/auth/magic/:code()",
    meta: _91code_93CTzcha3GaHMeta || {},
    alias: _91code_93CTzcha3GaHMeta?.alias || [],
    redirect: _91code_93CTzcha3GaHMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/auth/magic/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93CTzcha3GaHMeta?.name ?? "auth-magic-code___zh",
    path: _91code_93CTzcha3GaHMeta?.path ?? "/zh/auth/magic/:code()",
    meta: _91code_93CTzcha3GaHMeta || {},
    alias: _91code_93CTzcha3GaHMeta?.alias || [],
    redirect: _91code_93CTzcha3GaHMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/auth/magic/[code].vue").then(m => m.default || m)
  },
  {
    name: indexBf0gpAyrQLMeta?.name ?? "cart-event___en",
    path: indexBf0gpAyrQLMeta?.path ?? "/cart/:event()",
    meta: indexBf0gpAyrQLMeta || {},
    alias: indexBf0gpAyrQLMeta?.alias || [],
    redirect: indexBf0gpAyrQLMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/cart/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBf0gpAyrQLMeta?.name ?? "cart-event___ru",
    path: indexBf0gpAyrQLMeta?.path ?? "/ru/cart/:event()",
    meta: indexBf0gpAyrQLMeta || {},
    alias: indexBf0gpAyrQLMeta?.alias || [],
    redirect: indexBf0gpAyrQLMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/cart/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexBf0gpAyrQLMeta?.name ?? "cart-event___zh",
    path: indexBf0gpAyrQLMeta?.path ?? "/zh/cart/:event()",
    meta: indexBf0gpAyrQLMeta || {},
    alias: indexBf0gpAyrQLMeta?.alias || [],
    redirect: indexBf0gpAyrQLMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/cart/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: companiesC8UGzVORd2Meta?.name ?? "companies___en",
    path: companiesC8UGzVORd2Meta?.path ?? "/companies",
    meta: companiesC8UGzVORd2Meta || {},
    alias: companiesC8UGzVORd2Meta?.alias || [],
    redirect: companiesC8UGzVORd2Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/companies.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93R2b0a4PfK8Meta?.name ?? "companies-id___en",
    path: _91id_93R2b0a4PfK8Meta?.path ?? ":id()",
    meta: _91id_93R2b0a4PfK8Meta || {},
    alias: _91id_93R2b0a4PfK8Meta?.alias || [],
    redirect: _91id_93R2b0a4PfK8Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/companies/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: companiesC8UGzVORd2Meta?.name ?? "companies___ru",
    path: companiesC8UGzVORd2Meta?.path ?? "/ru/companies",
    meta: companiesC8UGzVORd2Meta || {},
    alias: companiesC8UGzVORd2Meta?.alias || [],
    redirect: companiesC8UGzVORd2Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/companies.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93R2b0a4PfK8Meta?.name ?? "companies-id___ru",
    path: _91id_93R2b0a4PfK8Meta?.path ?? ":id()",
    meta: _91id_93R2b0a4PfK8Meta || {},
    alias: _91id_93R2b0a4PfK8Meta?.alias || [],
    redirect: _91id_93R2b0a4PfK8Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/companies/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: companiesC8UGzVORd2Meta?.name ?? "companies___zh",
    path: companiesC8UGzVORd2Meta?.path ?? "/zh/companies",
    meta: companiesC8UGzVORd2Meta || {},
    alias: companiesC8UGzVORd2Meta?.alias || [],
    redirect: companiesC8UGzVORd2Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/companies.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93R2b0a4PfK8Meta?.name ?? "companies-id___zh",
    path: _91id_93R2b0a4PfK8Meta?.path ?? ":id()",
    meta: _91id_93R2b0a4PfK8Meta || {},
    alias: _91id_93R2b0a4PfK8Meta?.alias || [],
    redirect: _91id_93R2b0a4PfK8Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/companies/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: contactsT7Avz26Jg4Meta?.name ?? "contacts___en",
    path: contactsT7Avz26Jg4Meta?.path ?? "/contacts",
    meta: contactsT7Avz26Jg4Meta || {},
    alias: contactsT7Avz26Jg4Meta?.alias || [],
    redirect: contactsT7Avz26Jg4Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsT7Avz26Jg4Meta?.name ?? "contacts___ru",
    path: contactsT7Avz26Jg4Meta?.path ?? "/ru/contacts",
    meta: contactsT7Avz26Jg4Meta || {},
    alias: contactsT7Avz26Jg4Meta?.alias || [],
    redirect: contactsT7Avz26Jg4Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsT7Avz26Jg4Meta?.name ?? "contacts___zh",
    path: contactsT7Avz26Jg4Meta?.path ?? "/zh/contacts",
    meta: contactsT7Avz26Jg4Meta || {},
    alias: contactsT7Avz26Jg4Meta?.alias || [],
    redirect: contactsT7Avz26Jg4Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XFWnUkHGH8Meta?.name ?? "event-info-event-check-program-id___en",
    path: _91id_93XFWnUkHGH8Meta?.path ?? "/event-info/:event()/check-program/:id()",
    meta: _91id_93XFWnUkHGH8Meta || {},
    alias: _91id_93XFWnUkHGH8Meta?.alias || [],
    redirect: _91id_93XFWnUkHGH8Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/check-program/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XFWnUkHGH8Meta?.name ?? "event-info-event-check-program-id___ru",
    path: _91id_93XFWnUkHGH8Meta?.path ?? "/ru/event-info/:event()/check-program/:id()",
    meta: _91id_93XFWnUkHGH8Meta || {},
    alias: _91id_93XFWnUkHGH8Meta?.alias || [],
    redirect: _91id_93XFWnUkHGH8Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/check-program/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93XFWnUkHGH8Meta?.name ?? "event-info-event-check-program-id___zh",
    path: _91id_93XFWnUkHGH8Meta?.path ?? "/zh/event-info/:event()/check-program/:id()",
    meta: _91id_93XFWnUkHGH8Meta || {},
    alias: _91id_93XFWnUkHGH8Meta?.alias || [],
    redirect: _91id_93XFWnUkHGH8Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/check-program/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqOma72b19BMeta?.name ?? "event-info-event___en",
    path: indexqOma72b19BMeta?.path ?? "/event-info/:event()",
    meta: indexqOma72b19BMeta || {},
    alias: indexqOma72b19BMeta?.alias || [],
    redirect: indexqOma72b19BMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqOma72b19BMeta?.name ?? "event-info-event___ru",
    path: indexqOma72b19BMeta?.path ?? "/ru/event-info/:event()",
    meta: indexqOma72b19BMeta || {},
    alias: indexqOma72b19BMeta?.alias || [],
    redirect: indexqOma72b19BMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqOma72b19BMeta?.name ?? "event-info-event___zh",
    path: indexqOma72b19BMeta?.path ?? "/zh/event-info/:event()",
    meta: indexqOma72b19BMeta || {},
    alias: indexqOma72b19BMeta?.alias || [],
    redirect: indexqOma72b19BMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: public_45offerEt6MhiNRJgMeta?.name ?? "event-info-event-public-offer___en",
    path: public_45offerEt6MhiNRJgMeta?.path ?? "/event-info/:event()/public-offer",
    meta: public_45offerEt6MhiNRJgMeta || {},
    alias: public_45offerEt6MhiNRJgMeta?.alias || [],
    redirect: public_45offerEt6MhiNRJgMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/public-offer.vue").then(m => m.default || m)
  },
  {
    name: public_45offerEt6MhiNRJgMeta?.name ?? "event-info-event-public-offer___ru",
    path: public_45offerEt6MhiNRJgMeta?.path ?? "/ru/event-info/:event()/public-offer",
    meta: public_45offerEt6MhiNRJgMeta || {},
    alias: public_45offerEt6MhiNRJgMeta?.alias || [],
    redirect: public_45offerEt6MhiNRJgMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/public-offer.vue").then(m => m.default || m)
  },
  {
    name: public_45offerEt6MhiNRJgMeta?.name ?? "event-info-event-public-offer___zh",
    path: public_45offerEt6MhiNRJgMeta?.path ?? "/zh/event-info/:event()/public-offer",
    meta: public_45offerEt6MhiNRJgMeta || {},
    alias: public_45offerEt6MhiNRJgMeta?.alias || [],
    redirect: public_45offerEt6MhiNRJgMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/public-offer.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YKXWezcl9iMeta?.name ?? "event-info-event-showcase-slug___en",
    path: _91slug_93YKXWezcl9iMeta?.path ?? "/event-info/:event()/showcase/:slug()",
    meta: _91slug_93YKXWezcl9iMeta || {},
    alias: _91slug_93YKXWezcl9iMeta?.alias || [],
    redirect: _91slug_93YKXWezcl9iMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/showcase/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YKXWezcl9iMeta?.name ?? "event-info-event-showcase-slug___ru",
    path: _91slug_93YKXWezcl9iMeta?.path ?? "/ru/event-info/:event()/showcase/:slug()",
    meta: _91slug_93YKXWezcl9iMeta || {},
    alias: _91slug_93YKXWezcl9iMeta?.alias || [],
    redirect: _91slug_93YKXWezcl9iMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/showcase/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YKXWezcl9iMeta?.name ?? "event-info-event-showcase-slug___zh",
    path: _91slug_93YKXWezcl9iMeta?.path ?? "/zh/event-info/:event()/showcase/:slug()",
    meta: _91slug_93YKXWezcl9iMeta || {},
    alias: _91slug_93YKXWezcl9iMeta?.alias || [],
    redirect: _91slug_93YKXWezcl9iMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/[event]/showcase/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexC47lifGnVYMeta?.name ?? undefined,
    path: indexC47lifGnVYMeta?.path ?? "/event-info",
    meta: indexC47lifGnVYMeta || {},
    alias: indexC47lifGnVYMeta?.alias || [],
    redirect: indexC47lifGnVYMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index.vue").then(m => m.default || m),
    children: [
  {
    name: aboutuOA5JEbraEMeta?.name ?? "event-info-index-about___en",
    path: aboutuOA5JEbraEMeta?.path ?? "about",
    meta: aboutuOA5JEbraEMeta || {},
    alias: aboutuOA5JEbraEMeta?.alias || [],
    redirect: aboutuOA5JEbraEMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/about.vue").then(m => m.default || m)
  },
  {
    name: indexAMsIYRm4yWMeta?.name ?? "event-info-index___en",
    path: indexAMsIYRm4yWMeta?.path ?? "",
    meta: indexAMsIYRm4yWMeta || {},
    alias: indexAMsIYRm4yWMeta?.alias || [],
    redirect: indexAMsIYRm4yWMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/index.vue").then(m => m.default || m)
  },
  {
    name: myYf7WTaaQdjMeta?.name ?? "event-info-index-my___en",
    path: myYf7WTaaQdjMeta?.path ?? "my",
    meta: myYf7WTaaQdjMeta || {},
    alias: myYf7WTaaQdjMeta?.alias || [],
    redirect: myYf7WTaaQdjMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/my.vue").then(m => m.default || m)
  },
  {
    name: pastRCrboUPMmdMeta?.name ?? "event-info-index-past___en",
    path: pastRCrboUPMmdMeta?.path ?? "past",
    meta: pastRCrboUPMmdMeta || {},
    alias: pastRCrboUPMmdMeta?.alias || [],
    redirect: pastRCrboUPMmdMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/past.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexC47lifGnVYMeta?.name ?? undefined,
    path: indexC47lifGnVYMeta?.path ?? "/ru/event-info",
    meta: indexC47lifGnVYMeta || {},
    alias: indexC47lifGnVYMeta?.alias || [],
    redirect: indexC47lifGnVYMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index.vue").then(m => m.default || m),
    children: [
  {
    name: aboutuOA5JEbraEMeta?.name ?? "event-info-index-about___ru",
    path: aboutuOA5JEbraEMeta?.path ?? "about",
    meta: aboutuOA5JEbraEMeta || {},
    alias: aboutuOA5JEbraEMeta?.alias || [],
    redirect: aboutuOA5JEbraEMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/about.vue").then(m => m.default || m)
  },
  {
    name: indexAMsIYRm4yWMeta?.name ?? "event-info-index___ru",
    path: indexAMsIYRm4yWMeta?.path ?? "",
    meta: indexAMsIYRm4yWMeta || {},
    alias: indexAMsIYRm4yWMeta?.alias || [],
    redirect: indexAMsIYRm4yWMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/index.vue").then(m => m.default || m)
  },
  {
    name: myYf7WTaaQdjMeta?.name ?? "event-info-index-my___ru",
    path: myYf7WTaaQdjMeta?.path ?? "my",
    meta: myYf7WTaaQdjMeta || {},
    alias: myYf7WTaaQdjMeta?.alias || [],
    redirect: myYf7WTaaQdjMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/my.vue").then(m => m.default || m)
  },
  {
    name: pastRCrboUPMmdMeta?.name ?? "event-info-index-past___ru",
    path: pastRCrboUPMmdMeta?.path ?? "past",
    meta: pastRCrboUPMmdMeta || {},
    alias: pastRCrboUPMmdMeta?.alias || [],
    redirect: pastRCrboUPMmdMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/past.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexC47lifGnVYMeta?.name ?? undefined,
    path: indexC47lifGnVYMeta?.path ?? "/zh/event-info",
    meta: indexC47lifGnVYMeta || {},
    alias: indexC47lifGnVYMeta?.alias || [],
    redirect: indexC47lifGnVYMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index.vue").then(m => m.default || m),
    children: [
  {
    name: aboutuOA5JEbraEMeta?.name ?? "event-info-index-about___zh",
    path: aboutuOA5JEbraEMeta?.path ?? "about",
    meta: aboutuOA5JEbraEMeta || {},
    alias: aboutuOA5JEbraEMeta?.alias || [],
    redirect: aboutuOA5JEbraEMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/about.vue").then(m => m.default || m)
  },
  {
    name: indexAMsIYRm4yWMeta?.name ?? "event-info-index___zh",
    path: indexAMsIYRm4yWMeta?.path ?? "",
    meta: indexAMsIYRm4yWMeta || {},
    alias: indexAMsIYRm4yWMeta?.alias || [],
    redirect: indexAMsIYRm4yWMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/index.vue").then(m => m.default || m)
  },
  {
    name: myYf7WTaaQdjMeta?.name ?? "event-info-index-my___zh",
    path: myYf7WTaaQdjMeta?.path ?? "my",
    meta: myYf7WTaaQdjMeta || {},
    alias: myYf7WTaaQdjMeta?.alias || [],
    redirect: myYf7WTaaQdjMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/my.vue").then(m => m.default || m)
  },
  {
    name: pastRCrboUPMmdMeta?.name ?? "event-info-index-past___zh",
    path: pastRCrboUPMmdMeta?.path ?? "past",
    meta: pastRCrboUPMmdMeta || {},
    alias: pastRCrboUPMmdMeta?.alias || [],
    redirect: pastRCrboUPMmdMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/event-info/index/past.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93nwo1ITHx9MMeta?.name ?? "iframe-event-info-event-blocks-id___en",
    path: _91id_93nwo1ITHx9MMeta?.path ?? "/iframe/event-info/:event()/blocks/:id()",
    meta: _91id_93nwo1ITHx9MMeta || {},
    alias: _91id_93nwo1ITHx9MMeta?.alias || [],
    redirect: _91id_93nwo1ITHx9MMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/blocks/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93nwo1ITHx9MMeta?.name ?? "iframe-event-info-event-blocks-id___ru",
    path: _91id_93nwo1ITHx9MMeta?.path ?? "/ru/iframe/event-info/:event()/blocks/:id()",
    meta: _91id_93nwo1ITHx9MMeta || {},
    alias: _91id_93nwo1ITHx9MMeta?.alias || [],
    redirect: _91id_93nwo1ITHx9MMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/blocks/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93nwo1ITHx9MMeta?.name ?? "iframe-event-info-event-blocks-id___zh",
    path: _91id_93nwo1ITHx9MMeta?.path ?? "/zh/iframe/event-info/:event()/blocks/:id()",
    meta: _91id_93nwo1ITHx9MMeta || {},
    alias: _91id_93nwo1ITHx9MMeta?.alias || [],
    redirect: _91id_93nwo1ITHx9MMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/blocks/[id].vue").then(m => m.default || m)
  },
  {
    name: companies_45buttonCqvRtyl4tHMeta?.name ?? "iframe-event-info-event-companies-components-companies-button___en",
    path: companies_45buttonCqvRtyl4tHMeta?.path ?? "/iframe/event-info/:event()/companies/components/companies-button",
    meta: companies_45buttonCqvRtyl4tHMeta || {},
    alias: companies_45buttonCqvRtyl4tHMeta?.alias || [],
    redirect: companies_45buttonCqvRtyl4tHMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-button.vue").then(m => m.default || m)
  },
  {
    name: companies_45buttonCqvRtyl4tHMeta?.name ?? "iframe-event-info-event-companies-components-companies-button___ru",
    path: companies_45buttonCqvRtyl4tHMeta?.path ?? "/ru/iframe/event-info/:event()/companies/components/companies-button",
    meta: companies_45buttonCqvRtyl4tHMeta || {},
    alias: companies_45buttonCqvRtyl4tHMeta?.alias || [],
    redirect: companies_45buttonCqvRtyl4tHMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-button.vue").then(m => m.default || m)
  },
  {
    name: companies_45buttonCqvRtyl4tHMeta?.name ?? "iframe-event-info-event-companies-components-companies-button___zh",
    path: companies_45buttonCqvRtyl4tHMeta?.path ?? "/zh/iframe/event-info/:event()/companies/components/companies-button",
    meta: companies_45buttonCqvRtyl4tHMeta || {},
    alias: companies_45buttonCqvRtyl4tHMeta?.alias || [],
    redirect: companies_45buttonCqvRtyl4tHMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-button.vue").then(m => m.default || m)
  },
  {
    name: companies_45tableVzoq5VWmDKMeta?.name ?? "iframe-event-info-event-companies-components-companies-table___en",
    path: companies_45tableVzoq5VWmDKMeta?.path ?? "/iframe/event-info/:event()/companies/components/companies-table",
    meta: companies_45tableVzoq5VWmDKMeta || {},
    alias: companies_45tableVzoq5VWmDKMeta?.alias || [],
    redirect: companies_45tableVzoq5VWmDKMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-table.vue").then(m => m.default || m)
  },
  {
    name: companies_45tableVzoq5VWmDKMeta?.name ?? "iframe-event-info-event-companies-components-companies-table___ru",
    path: companies_45tableVzoq5VWmDKMeta?.path ?? "/ru/iframe/event-info/:event()/companies/components/companies-table",
    meta: companies_45tableVzoq5VWmDKMeta || {},
    alias: companies_45tableVzoq5VWmDKMeta?.alias || [],
    redirect: companies_45tableVzoq5VWmDKMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-table.vue").then(m => m.default || m)
  },
  {
    name: companies_45tableVzoq5VWmDKMeta?.name ?? "iframe-event-info-event-companies-components-companies-table___zh",
    path: companies_45tableVzoq5VWmDKMeta?.path ?? "/zh/iframe/event-info/:event()/companies/components/companies-table",
    meta: companies_45tableVzoq5VWmDKMeta || {},
    alias: companies_45tableVzoq5VWmDKMeta?.alias || [],
    redirect: companies_45tableVzoq5VWmDKMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/components/companies-table.vue").then(m => m.default || m)
  },
  {
    name: indexQkzLiatZ3LMeta?.name ?? "iframe-event-info-event-companies___en",
    path: indexQkzLiatZ3LMeta?.path ?? "/iframe/event-info/:event()/companies",
    meta: indexQkzLiatZ3LMeta || {},
    alias: indexQkzLiatZ3LMeta?.alias || [],
    redirect: indexQkzLiatZ3LMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexQkzLiatZ3LMeta?.name ?? "iframe-event-info-event-companies___ru",
    path: indexQkzLiatZ3LMeta?.path ?? "/ru/iframe/event-info/:event()/companies",
    meta: indexQkzLiatZ3LMeta || {},
    alias: indexQkzLiatZ3LMeta?.alias || [],
    redirect: indexQkzLiatZ3LMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexQkzLiatZ3LMeta?.name ?? "iframe-event-info-event-companies___zh",
    path: indexQkzLiatZ3LMeta?.path ?? "/zh/iframe/event-info/:event()/companies",
    meta: indexQkzLiatZ3LMeta || {},
    alias: indexQkzLiatZ3LMeta?.alias || [],
    redirect: indexQkzLiatZ3LMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/iframe/event-info/[event]/companies/index.vue").then(m => m.default || m)
  },
  {
    name: index_45test_45page6SCyhW5jsUMeta?.name ?? "index-test-page___en",
    path: index_45test_45page6SCyhW5jsUMeta?.path ?? "/index-test-page",
    meta: index_45test_45page6SCyhW5jsUMeta || {},
    alias: index_45test_45page6SCyhW5jsUMeta?.alias || [],
    redirect: index_45test_45page6SCyhW5jsUMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/index-test-page.vue").then(m => m.default || m)
  },
  {
    name: index_45test_45page6SCyhW5jsUMeta?.name ?? "index-test-page___ru",
    path: index_45test_45page6SCyhW5jsUMeta?.path ?? "/ru/index-test-page",
    meta: index_45test_45page6SCyhW5jsUMeta || {},
    alias: index_45test_45page6SCyhW5jsUMeta?.alias || [],
    redirect: index_45test_45page6SCyhW5jsUMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/index-test-page.vue").then(m => m.default || m)
  },
  {
    name: index_45test_45page6SCyhW5jsUMeta?.name ?? "index-test-page___zh",
    path: index_45test_45page6SCyhW5jsUMeta?.path ?? "/zh/index-test-page",
    meta: index_45test_45page6SCyhW5jsUMeta || {},
    alias: index_45test_45page6SCyhW5jsUMeta?.alias || [],
    redirect: index_45test_45page6SCyhW5jsUMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/index-test-page.vue").then(m => m.default || m)
  },
  {
    name: indexFmwB729zM7Meta?.name ?? "index___en",
    path: indexFmwB729zM7Meta?.path ?? "/",
    meta: indexFmwB729zM7Meta || {},
    alias: indexFmwB729zM7Meta?.alias || [],
    redirect: indexFmwB729zM7Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexFmwB729zM7Meta?.name ?? "index___ru",
    path: indexFmwB729zM7Meta?.path ?? "/ru",
    meta: indexFmwB729zM7Meta || {},
    alias: indexFmwB729zM7Meta?.alias || [],
    redirect: indexFmwB729zM7Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexFmwB729zM7Meta?.name ?? "index___zh",
    path: indexFmwB729zM7Meta?.path ?? "/zh",
    meta: indexFmwB729zM7Meta || {},
    alias: indexFmwB729zM7Meta?.alias || [],
    redirect: indexFmwB729zM7Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexpR99VCX0nkMeta?.name ?? undefined,
    path: indexpR99VCX0nkMeta?.path ?? "/marketplace",
    meta: indexpR99VCX0nkMeta || {},
    alias: indexpR99VCX0nkMeta?.alias || [],
    redirect: indexpR99VCX0nkMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexOsmfc3IjbdMeta?.name ?? "marketplace-index___en",
    path: indexOsmfc3IjbdMeta?.path ?? "",
    meta: indexOsmfc3IjbdMeta || {},
    alias: indexOsmfc3IjbdMeta?.alias || [],
    redirect: indexOsmfc3IjbdMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index/index.vue").then(m => m.default || m)
  },
  {
    name: offer_45serviceh12PnkFbmXMeta?.name ?? "marketplace-index-offer-service___en",
    path: offer_45serviceh12PnkFbmXMeta?.path ?? "offer-service",
    meta: offer_45serviceh12PnkFbmXMeta || {},
    alias: offer_45serviceh12PnkFbmXMeta?.alias || [],
    redirect: offer_45serviceh12PnkFbmXMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index/offer-service.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93QAYGP7Q62ZMeta?.name ?? "marketplace-index-products-productId___en",
    path: _91productId_93QAYGP7Q62ZMeta?.path ?? "products/:productId()",
    meta: _91productId_93QAYGP7Q62ZMeta || {},
    alias: _91productId_93QAYGP7Q62ZMeta?.alias || [],
    redirect: _91productId_93QAYGP7Q62ZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index/products/[productId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexpR99VCX0nkMeta?.name ?? undefined,
    path: indexpR99VCX0nkMeta?.path ?? "/ru/marketplace",
    meta: indexpR99VCX0nkMeta || {},
    alias: indexpR99VCX0nkMeta?.alias || [],
    redirect: indexpR99VCX0nkMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexOsmfc3IjbdMeta?.name ?? "marketplace-index___ru",
    path: indexOsmfc3IjbdMeta?.path ?? "",
    meta: indexOsmfc3IjbdMeta || {},
    alias: indexOsmfc3IjbdMeta?.alias || [],
    redirect: indexOsmfc3IjbdMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index/index.vue").then(m => m.default || m)
  },
  {
    name: offer_45serviceh12PnkFbmXMeta?.name ?? "marketplace-index-offer-service___ru",
    path: offer_45serviceh12PnkFbmXMeta?.path ?? "offer-service",
    meta: offer_45serviceh12PnkFbmXMeta || {},
    alias: offer_45serviceh12PnkFbmXMeta?.alias || [],
    redirect: offer_45serviceh12PnkFbmXMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index/offer-service.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93QAYGP7Q62ZMeta?.name ?? "marketplace-index-products-productId___ru",
    path: _91productId_93QAYGP7Q62ZMeta?.path ?? "products/:productId()",
    meta: _91productId_93QAYGP7Q62ZMeta || {},
    alias: _91productId_93QAYGP7Q62ZMeta?.alias || [],
    redirect: _91productId_93QAYGP7Q62ZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index/products/[productId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexpR99VCX0nkMeta?.name ?? undefined,
    path: indexpR99VCX0nkMeta?.path ?? "/zh/marketplace",
    meta: indexpR99VCX0nkMeta || {},
    alias: indexpR99VCX0nkMeta?.alias || [],
    redirect: indexpR99VCX0nkMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexOsmfc3IjbdMeta?.name ?? "marketplace-index___zh",
    path: indexOsmfc3IjbdMeta?.path ?? "",
    meta: indexOsmfc3IjbdMeta || {},
    alias: indexOsmfc3IjbdMeta?.alias || [],
    redirect: indexOsmfc3IjbdMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index/index.vue").then(m => m.default || m)
  },
  {
    name: offer_45serviceh12PnkFbmXMeta?.name ?? "marketplace-index-offer-service___zh",
    path: offer_45serviceh12PnkFbmXMeta?.path ?? "offer-service",
    meta: offer_45serviceh12PnkFbmXMeta || {},
    alias: offer_45serviceh12PnkFbmXMeta?.alias || [],
    redirect: offer_45serviceh12PnkFbmXMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index/offer-service.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93QAYGP7Q62ZMeta?.name ?? "marketplace-index-products-productId___zh",
    path: _91productId_93QAYGP7Q62ZMeta?.path ?? "products/:productId()",
    meta: _91productId_93QAYGP7Q62ZMeta || {},
    alias: _91productId_93QAYGP7Q62ZMeta?.alias || [],
    redirect: _91productId_93QAYGP7Q62ZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/marketplace/index/products/[productId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: newscWS5WuBcNCMeta?.name ?? undefined,
    path: newscWS5WuBcNCMeta?.path ?? "/news",
    meta: newscWS5WuBcNCMeta || {},
    alias: newscWS5WuBcNCMeta?.alias || [],
    redirect: newscWS5WuBcNCMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_9324I41V4sYiMeta?.name ?? undefined,
    path: _91category_9324I41V4sYiMeta?.path ?? ":category()",
    meta: _91category_9324I41V4sYiMeta || {},
    alias: _91category_9324I41V4sYiMeta?.alias || [],
    redirect: _91category_9324I41V4sYiMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category].vue").then(m => m.default || m),
    children: [
  {
    name: index8bftAv4JtkMeta?.name ?? "news-category___en",
    path: index8bftAv4JtkMeta?.path ?? "",
    meta: index8bftAv4JtkMeta || {},
    alias: index8bftAv4JtkMeta?.alias || [],
    redirect: index8bftAv4JtkMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_93yGPN88Q2q1Meta?.name ?? "news-category-item-id___en",
    path: item_45_91id_93yGPN88Q2q1Meta?.path ?? "item-:id()",
    meta: item_45_91id_93yGPN88Q2q1Meta || {},
    alias: item_45_91id_93yGPN88Q2q1Meta?.alias || [],
    redirect: item_45_91id_93yGPN88Q2q1Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: popularc2hRwihuY3Meta?.name ?? "news-category-popular___en",
    path: popularc2hRwihuY3Meta?.path ?? "popular",
    meta: popularc2hRwihuY3Meta || {},
    alias: popularc2hRwihuY3Meta?.alias || [],
    redirect: popularc2hRwihuY3Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumE5T9S1DxDHMeta?.name ?? "news-category-premium___en",
    path: premiumE5T9S1DxDHMeta?.path ?? "premium",
    meta: premiumE5T9S1DxDHMeta || {},
    alias: premiumE5T9S1DxDHMeta?.alias || [],
    redirect: premiumE5T9S1DxDHMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/premium.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexCA62VUbi3xMeta?.name ?? "news___en",
    path: indexCA62VUbi3xMeta?.path ?? "",
    meta: indexCA62VUbi3xMeta || {},
    alias: indexCA62VUbi3xMeta?.alias || [],
    redirect: indexCA62VUbi3xMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: popularZhHeGoyDWIMeta?.name ?? "news-popular___en",
    path: popularZhHeGoyDWIMeta?.path ?? "popular",
    meta: popularZhHeGoyDWIMeta || {},
    alias: popularZhHeGoyDWIMeta?.alias || [],
    redirect: popularZhHeGoyDWIMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumlv8SRVLlYZMeta?.name ?? "news-premium___en",
    path: premiumlv8SRVLlYZMeta?.path ?? "premium",
    meta: premiumlv8SRVLlYZMeta || {},
    alias: premiumlv8SRVLlYZMeta?.alias || [],
    redirect: premiumlv8SRVLlYZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/premium.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newscWS5WuBcNCMeta?.name ?? undefined,
    path: newscWS5WuBcNCMeta?.path ?? "/ru/news",
    meta: newscWS5WuBcNCMeta || {},
    alias: newscWS5WuBcNCMeta?.alias || [],
    redirect: newscWS5WuBcNCMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_9324I41V4sYiMeta?.name ?? undefined,
    path: _91category_9324I41V4sYiMeta?.path ?? ":category()",
    meta: _91category_9324I41V4sYiMeta || {},
    alias: _91category_9324I41V4sYiMeta?.alias || [],
    redirect: _91category_9324I41V4sYiMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category].vue").then(m => m.default || m),
    children: [
  {
    name: index8bftAv4JtkMeta?.name ?? "news-category___ru",
    path: index8bftAv4JtkMeta?.path ?? "",
    meta: index8bftAv4JtkMeta || {},
    alias: index8bftAv4JtkMeta?.alias || [],
    redirect: index8bftAv4JtkMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_93yGPN88Q2q1Meta?.name ?? "news-category-item-id___ru",
    path: item_45_91id_93yGPN88Q2q1Meta?.path ?? "item-:id()",
    meta: item_45_91id_93yGPN88Q2q1Meta || {},
    alias: item_45_91id_93yGPN88Q2q1Meta?.alias || [],
    redirect: item_45_91id_93yGPN88Q2q1Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: popularc2hRwihuY3Meta?.name ?? "news-category-popular___ru",
    path: popularc2hRwihuY3Meta?.path ?? "popular",
    meta: popularc2hRwihuY3Meta || {},
    alias: popularc2hRwihuY3Meta?.alias || [],
    redirect: popularc2hRwihuY3Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumE5T9S1DxDHMeta?.name ?? "news-category-premium___ru",
    path: premiumE5T9S1DxDHMeta?.path ?? "premium",
    meta: premiumE5T9S1DxDHMeta || {},
    alias: premiumE5T9S1DxDHMeta?.alias || [],
    redirect: premiumE5T9S1DxDHMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/premium.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexCA62VUbi3xMeta?.name ?? "news___ru",
    path: indexCA62VUbi3xMeta?.path ?? "",
    meta: indexCA62VUbi3xMeta || {},
    alias: indexCA62VUbi3xMeta?.alias || [],
    redirect: indexCA62VUbi3xMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: popularZhHeGoyDWIMeta?.name ?? "news-popular___ru",
    path: popularZhHeGoyDWIMeta?.path ?? "popular",
    meta: popularZhHeGoyDWIMeta || {},
    alias: popularZhHeGoyDWIMeta?.alias || [],
    redirect: popularZhHeGoyDWIMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumlv8SRVLlYZMeta?.name ?? "news-premium___ru",
    path: premiumlv8SRVLlYZMeta?.path ?? "premium",
    meta: premiumlv8SRVLlYZMeta || {},
    alias: premiumlv8SRVLlYZMeta?.alias || [],
    redirect: premiumlv8SRVLlYZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/premium.vue").then(m => m.default || m)
  }
]
  },
  {
    name: newscWS5WuBcNCMeta?.name ?? undefined,
    path: newscWS5WuBcNCMeta?.path ?? "/zh/news",
    meta: newscWS5WuBcNCMeta || {},
    alias: newscWS5WuBcNCMeta?.alias || [],
    redirect: newscWS5WuBcNCMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_9324I41V4sYiMeta?.name ?? undefined,
    path: _91category_9324I41V4sYiMeta?.path ?? ":category()",
    meta: _91category_9324I41V4sYiMeta || {},
    alias: _91category_9324I41V4sYiMeta?.alias || [],
    redirect: _91category_9324I41V4sYiMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category].vue").then(m => m.default || m),
    children: [
  {
    name: index8bftAv4JtkMeta?.name ?? "news-category___zh",
    path: index8bftAv4JtkMeta?.path ?? "",
    meta: index8bftAv4JtkMeta || {},
    alias: index8bftAv4JtkMeta?.alias || [],
    redirect: index8bftAv4JtkMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_93yGPN88Q2q1Meta?.name ?? "news-category-item-id___zh",
    path: item_45_91id_93yGPN88Q2q1Meta?.path ?? "item-:id()",
    meta: item_45_91id_93yGPN88Q2q1Meta || {},
    alias: item_45_91id_93yGPN88Q2q1Meta?.alias || [],
    redirect: item_45_91id_93yGPN88Q2q1Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: popularc2hRwihuY3Meta?.name ?? "news-category-popular___zh",
    path: popularc2hRwihuY3Meta?.path ?? "popular",
    meta: popularc2hRwihuY3Meta || {},
    alias: popularc2hRwihuY3Meta?.alias || [],
    redirect: popularc2hRwihuY3Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumE5T9S1DxDHMeta?.name ?? "news-category-premium___zh",
    path: premiumE5T9S1DxDHMeta?.path ?? "premium",
    meta: premiumE5T9S1DxDHMeta || {},
    alias: premiumE5T9S1DxDHMeta?.alias || [],
    redirect: premiumE5T9S1DxDHMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/[category]/premium.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexCA62VUbi3xMeta?.name ?? "news___zh",
    path: indexCA62VUbi3xMeta?.path ?? "",
    meta: indexCA62VUbi3xMeta || {},
    alias: indexCA62VUbi3xMeta?.alias || [],
    redirect: indexCA62VUbi3xMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: popularZhHeGoyDWIMeta?.name ?? "news-popular___zh",
    path: popularZhHeGoyDWIMeta?.path ?? "popular",
    meta: popularZhHeGoyDWIMeta || {},
    alias: popularZhHeGoyDWIMeta?.alias || [],
    redirect: popularZhHeGoyDWIMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/popular.vue").then(m => m.default || m)
  },
  {
    name: premiumlv8SRVLlYZMeta?.name ?? "news-premium___zh",
    path: premiumlv8SRVLlYZMeta?.path ?? "premium",
    meta: premiumlv8SRVLlYZMeta || {},
    alias: premiumlv8SRVLlYZMeta?.alias || [],
    redirect: premiumlv8SRVLlYZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/news/premium.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFvWmjD1TILMeta?.name ?? "packages-cart-event___en",
    path: indexFvWmjD1TILMeta?.path ?? "/packages-cart/:event()",
    meta: indexFvWmjD1TILMeta || {},
    alias: indexFvWmjD1TILMeta?.alias || [],
    redirect: indexFvWmjD1TILMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/packages-cart/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFvWmjD1TILMeta?.name ?? "packages-cart-event___ru",
    path: indexFvWmjD1TILMeta?.path ?? "/ru/packages-cart/:event()",
    meta: indexFvWmjD1TILMeta || {},
    alias: indexFvWmjD1TILMeta?.alias || [],
    redirect: indexFvWmjD1TILMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/packages-cart/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFvWmjD1TILMeta?.name ?? "packages-cart-event___zh",
    path: indexFvWmjD1TILMeta?.path ?? "/zh/packages-cart/:event()",
    meta: indexFvWmjD1TILMeta || {},
    alias: indexFvWmjD1TILMeta?.alias || [],
    redirect: indexFvWmjD1TILMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/packages-cart/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyPreT20nEW6Meta?.name ?? "privacy-policy___en",
    path: privacy_45policyPreT20nEW6Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyPreT20nEW6Meta || {},
    alias: privacy_45policyPreT20nEW6Meta?.alias || [],
    redirect: privacy_45policyPreT20nEW6Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyPreT20nEW6Meta?.name ?? "privacy-policy___ru",
    path: privacy_45policyPreT20nEW6Meta?.path ?? "/ru/privacy-policy",
    meta: privacy_45policyPreT20nEW6Meta || {},
    alias: privacy_45policyPreT20nEW6Meta?.alias || [],
    redirect: privacy_45policyPreT20nEW6Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyPreT20nEW6Meta?.name ?? "privacy-policy___zh",
    path: privacy_45policyPreT20nEW6Meta?.path ?? "/zh/privacy-policy",
    meta: privacy_45policyPreT20nEW6Meta || {},
    alias: privacy_45policyPreT20nEW6Meta?.alias || [],
    redirect: privacy_45policyPreT20nEW6Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: pro0BKHPgHHJWMeta?.name ?? "pro___en",
    path: pro0BKHPgHHJWMeta?.path ?? "/pro",
    meta: pro0BKHPgHHJWMeta || {},
    alias: pro0BKHPgHHJWMeta?.alias || [],
    redirect: pro0BKHPgHHJWMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/pro.vue").then(m => m.default || m)
  },
  {
    name: pro0BKHPgHHJWMeta?.name ?? "pro___ru",
    path: pro0BKHPgHHJWMeta?.path ?? "/ru/pro",
    meta: pro0BKHPgHHJWMeta || {},
    alias: pro0BKHPgHHJWMeta?.alias || [],
    redirect: pro0BKHPgHHJWMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/pro.vue").then(m => m.default || m)
  },
  {
    name: pro0BKHPgHHJWMeta?.name ?? "pro___zh",
    path: pro0BKHPgHHJWMeta?.path ?? "/zh/pro",
    meta: pro0BKHPgHHJWMeta || {},
    alias: pro0BKHPgHHJWMeta?.alias || [],
    redirect: pro0BKHPgHHJWMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/pro.vue").then(m => m.default || m)
  },
  {
    name: questions4baAEHAd2jMeta?.name ?? undefined,
    path: questions4baAEHAd2jMeta?.path ?? "/questions",
    meta: questions4baAEHAd2jMeta || {},
    alias: questions4baAEHAd2jMeta?.alias || [],
    redirect: questions4baAEHAd2jMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93MyRxgsPjfTMeta?.name ?? undefined,
    path: _91category_93MyRxgsPjfTMeta?.path ?? ":category()",
    meta: _91category_93MyRxgsPjfTMeta || {},
    alias: _91category_93MyRxgsPjfTMeta?.alias || [],
    redirect: _91category_93MyRxgsPjfTMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category].vue").then(m => m.default || m),
    children: [
  {
    name: indexzpUTVcJJVkMeta?.name ?? "questions-category___en",
    path: indexzpUTVcJJVkMeta?.path ?? "",
    meta: indexzpUTVcJJVkMeta || {},
    alias: indexzpUTVcJJVkMeta?.alias || [],
    redirect: indexzpUTVcJJVkMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_934JBI1qa0MgMeta?.name ?? "questions-category-item-id___en",
    path: item_45_91id_934JBI1qa0MgMeta?.path ?? "item-:id()",
    meta: item_45_91id_934JBI1qa0MgMeta || {},
    alias: item_45_91id_934JBI1qa0MgMeta?.alias || [],
    redirect: item_45_91id_934JBI1qa0MgMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: popularu9c8F03KmoMeta?.name ?? "questions-category-popular___en",
    path: popularu9c8F03KmoMeta?.path ?? "popular",
    meta: popularu9c8F03KmoMeta || {},
    alias: popularu9c8F03KmoMeta?.alias || [],
    redirect: popularu9c8F03KmoMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexhL04ZDQ82tMeta?.name ?? "questions___en",
    path: indexhL04ZDQ82tMeta?.path ?? "",
    meta: indexhL04ZDQ82tMeta || {},
    alias: indexhL04ZDQ82tMeta?.alias || [],
    redirect: indexhL04ZDQ82tMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: popularbzwlaMwNMiMeta?.name ?? "questions-popular___en",
    path: popularbzwlaMwNMiMeta?.path ?? "popular",
    meta: popularbzwlaMwNMiMeta || {},
    alias: popularbzwlaMwNMiMeta?.alias || [],
    redirect: popularbzwlaMwNMiMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: questions4baAEHAd2jMeta?.name ?? undefined,
    path: questions4baAEHAd2jMeta?.path ?? "/ru/questions",
    meta: questions4baAEHAd2jMeta || {},
    alias: questions4baAEHAd2jMeta?.alias || [],
    redirect: questions4baAEHAd2jMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93MyRxgsPjfTMeta?.name ?? undefined,
    path: _91category_93MyRxgsPjfTMeta?.path ?? ":category()",
    meta: _91category_93MyRxgsPjfTMeta || {},
    alias: _91category_93MyRxgsPjfTMeta?.alias || [],
    redirect: _91category_93MyRxgsPjfTMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category].vue").then(m => m.default || m),
    children: [
  {
    name: indexzpUTVcJJVkMeta?.name ?? "questions-category___ru",
    path: indexzpUTVcJJVkMeta?.path ?? "",
    meta: indexzpUTVcJJVkMeta || {},
    alias: indexzpUTVcJJVkMeta?.alias || [],
    redirect: indexzpUTVcJJVkMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_934JBI1qa0MgMeta?.name ?? "questions-category-item-id___ru",
    path: item_45_91id_934JBI1qa0MgMeta?.path ?? "item-:id()",
    meta: item_45_91id_934JBI1qa0MgMeta || {},
    alias: item_45_91id_934JBI1qa0MgMeta?.alias || [],
    redirect: item_45_91id_934JBI1qa0MgMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: popularu9c8F03KmoMeta?.name ?? "questions-category-popular___ru",
    path: popularu9c8F03KmoMeta?.path ?? "popular",
    meta: popularu9c8F03KmoMeta || {},
    alias: popularu9c8F03KmoMeta?.alias || [],
    redirect: popularu9c8F03KmoMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexhL04ZDQ82tMeta?.name ?? "questions___ru",
    path: indexhL04ZDQ82tMeta?.path ?? "",
    meta: indexhL04ZDQ82tMeta || {},
    alias: indexhL04ZDQ82tMeta?.alias || [],
    redirect: indexhL04ZDQ82tMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: popularbzwlaMwNMiMeta?.name ?? "questions-popular___ru",
    path: popularbzwlaMwNMiMeta?.path ?? "popular",
    meta: popularbzwlaMwNMiMeta || {},
    alias: popularbzwlaMwNMiMeta?.alias || [],
    redirect: popularbzwlaMwNMiMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: questions4baAEHAd2jMeta?.name ?? undefined,
    path: questions4baAEHAd2jMeta?.path ?? "/zh/questions",
    meta: questions4baAEHAd2jMeta || {},
    alias: questions4baAEHAd2jMeta?.alias || [],
    redirect: questions4baAEHAd2jMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93MyRxgsPjfTMeta?.name ?? undefined,
    path: _91category_93MyRxgsPjfTMeta?.path ?? ":category()",
    meta: _91category_93MyRxgsPjfTMeta || {},
    alias: _91category_93MyRxgsPjfTMeta?.alias || [],
    redirect: _91category_93MyRxgsPjfTMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category].vue").then(m => m.default || m),
    children: [
  {
    name: indexzpUTVcJJVkMeta?.name ?? "questions-category___zh",
    path: indexzpUTVcJJVkMeta?.path ?? "",
    meta: indexzpUTVcJJVkMeta || {},
    alias: indexzpUTVcJJVkMeta?.alias || [],
    redirect: indexzpUTVcJJVkMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_934JBI1qa0MgMeta?.name ?? "questions-category-item-id___zh",
    path: item_45_91id_934JBI1qa0MgMeta?.path ?? "item-:id()",
    meta: item_45_91id_934JBI1qa0MgMeta || {},
    alias: item_45_91id_934JBI1qa0MgMeta?.alias || [],
    redirect: item_45_91id_934JBI1qa0MgMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: popularu9c8F03KmoMeta?.name ?? "questions-category-popular___zh",
    path: popularu9c8F03KmoMeta?.path ?? "popular",
    meta: popularu9c8F03KmoMeta || {},
    alias: popularu9c8F03KmoMeta?.alias || [],
    redirect: popularu9c8F03KmoMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/[category]/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexhL04ZDQ82tMeta?.name ?? "questions___zh",
    path: indexhL04ZDQ82tMeta?.path ?? "",
    meta: indexhL04ZDQ82tMeta || {},
    alias: indexhL04ZDQ82tMeta?.alias || [],
    redirect: indexhL04ZDQ82tMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: popularbzwlaMwNMiMeta?.name ?? "questions-popular___zh",
    path: popularbzwlaMwNMiMeta?.path ?? "popular",
    meta: popularbzwlaMwNMiMeta || {},
    alias: popularbzwlaMwNMiMeta?.alias || [],
    redirect: popularbzwlaMwNMiMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/questions/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: talentsJtQk1uDUztMeta?.name ?? "talents___en",
    path: talentsJtQk1uDUztMeta?.path ?? "/talents",
    meta: talentsJtQk1uDUztMeta || {},
    alias: talentsJtQk1uDUztMeta?.alias || [],
    redirect: talentsJtQk1uDUztMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/talents.vue").then(m => m.default || m)
  },
  {
    name: talentsJtQk1uDUztMeta?.name ?? "talents___ru",
    path: talentsJtQk1uDUztMeta?.path ?? "/ru/talents",
    meta: talentsJtQk1uDUztMeta || {},
    alias: talentsJtQk1uDUztMeta?.alias || [],
    redirect: talentsJtQk1uDUztMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/talents.vue").then(m => m.default || m)
  },
  {
    name: talentsJtQk1uDUztMeta?.name ?? "talents___zh",
    path: talentsJtQk1uDUztMeta?.path ?? "/zh/talents",
    meta: talentsJtQk1uDUztMeta || {},
    alias: talentsJtQk1uDUztMeta?.alias || [],
    redirect: talentsJtQk1uDUztMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/talents.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsZelWAqjpD9Meta?.name ?? "terms-conditions___en",
    path: terms_45conditionsZelWAqjpD9Meta?.path ?? "/terms-conditions",
    meta: terms_45conditionsZelWAqjpD9Meta || {},
    alias: terms_45conditionsZelWAqjpD9Meta?.alias || [],
    redirect: terms_45conditionsZelWAqjpD9Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsZelWAqjpD9Meta?.name ?? "terms-conditions___ru",
    path: terms_45conditionsZelWAqjpD9Meta?.path ?? "/ru/terms-conditions",
    meta: terms_45conditionsZelWAqjpD9Meta || {},
    alias: terms_45conditionsZelWAqjpD9Meta?.alias || [],
    redirect: terms_45conditionsZelWAqjpD9Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsZelWAqjpD9Meta?.name ?? "terms-conditions___zh",
    path: terms_45conditionsZelWAqjpD9Meta?.path ?? "/zh/terms-conditions",
    meta: terms_45conditionsZelWAqjpD9Meta || {},
    alias: terms_45conditionsZelWAqjpD9Meta?.alias || [],
    redirect: terms_45conditionsZelWAqjpD9Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: usersjYpGzagbSDMeta?.name ?? "users___en",
    path: usersjYpGzagbSDMeta?.path ?? "/users",
    meta: usersjYpGzagbSDMeta || {},
    alias: usersjYpGzagbSDMeta?.alias || [],
    redirect: usersjYpGzagbSDMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93OUqbpMOdbNMeta?.name ?? "users-id___en",
    path: _91id_93OUqbpMOdbNMeta?.path ?? ":id()",
    meta: _91id_93OUqbpMOdbNMeta || {},
    alias: _91id_93OUqbpMOdbNMeta?.alias || [],
    redirect: _91id_93OUqbpMOdbNMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: mevstyywGv64Meta?.name ?? undefined,
    path: mevstyywGv64Meta?.path ?? "me",
    meta: mevstyywGv64Meta || {},
    alias: mevstyywGv64Meta?.alias || [],
    redirect: mevstyywGv64Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me.vue").then(m => m.default || m),
    children: [
  {
    name: basicaE4lV5nEhFMeta?.name ?? "users-me-edit-basic___en",
    path: basicaE4lV5nEhFMeta?.path ?? "edit/basic",
    meta: basicaE4lV5nEhFMeta || {},
    alias: basicaE4lV5nEhFMeta?.alias || [],
    redirect: basicaE4lV5nEhFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/basic.vue").then(m => m.default || m)
  },
  {
    name: company_45membersmNN7CPxLWiMeta?.name ?? "users-me-edit-company-members___en",
    path: company_45membersmNN7CPxLWiMeta?.path ?? "edit/company-members",
    meta: company_45membersmNN7CPxLWiMeta || {},
    alias: company_45membersmNN7CPxLWiMeta?.alias || [],
    redirect: company_45membersmNN7CPxLWiMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/company-members.vue").then(m => m.default || m)
  },
  {
    name: settingszMj39xeeQlMeta?.name ?? "users-me-edit-settings___en",
    path: settingszMj39xeeQlMeta?.path ?? "edit/settings",
    meta: settingszMj39xeeQlMeta || {},
    alias: settingszMj39xeeQlMeta?.alias || [],
    redirect: settingszMj39xeeQlMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/settings.vue").then(m => m.default || m)
  },
  {
    name: favoritesUKR4dhyZzQMeta?.name ?? undefined,
    path: favoritesUKR4dhyZzQMeta?.path ?? "favorites",
    meta: favoritesUKR4dhyZzQMeta || {},
    alias: favoritesUKR4dhyZzQMeta?.alias || [],
    redirect: favoritesUKR4dhyZzQMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites.vue").then(m => m.default || m),
    children: [
  {
    name: companiesAUZiN9xmTWMeta?.name ?? "users-me-favorites-companies___en",
    path: companiesAUZiN9xmTWMeta?.path ?? "companies",
    meta: companiesAUZiN9xmTWMeta || {},
    alias: companiesAUZiN9xmTWMeta?.alias || [],
    redirect: companiesAUZiN9xmTWMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/companies.vue").then(m => m.default || m)
  },
  {
    name: eventsIHguZlQRmaMeta?.name ?? "users-me-favorites-events___en",
    path: eventsIHguZlQRmaMeta?.path ?? "events",
    meta: eventsIHguZlQRmaMeta || {},
    alias: eventsIHguZlQRmaMeta?.alias || [],
    redirect: eventsIHguZlQRmaMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/events.vue").then(m => m.default || m)
  },
  {
    name: indexI47ilk6aIyMeta?.name ?? "users-me-favorites___en",
    path: indexI47ilk6aIyMeta?.path ?? "",
    meta: indexI47ilk6aIyMeta || {},
    alias: indexI47ilk6aIyMeta?.alias || [],
    redirect: indexI47ilk6aIyMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: lecturesJDRRZeNP9uMeta?.name ?? "users-me-favorites-lectures___en",
    path: lecturesJDRRZeNP9uMeta?.path ?? "lectures",
    meta: lecturesJDRRZeNP9uMeta || {},
    alias: lecturesJDRRZeNP9uMeta?.alias || [],
    redirect: lecturesJDRRZeNP9uMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/lectures.vue").then(m => m.default || m)
  },
  {
    name: newsjJNp6IttrFMeta?.name ?? "users-me-favorites-news___en",
    path: newsjJNp6IttrFMeta?.path ?? "news",
    meta: newsjJNp6IttrFMeta || {},
    alias: newsjJNp6IttrFMeta?.alias || [],
    redirect: newsjJNp6IttrFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/news.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editKUQzd1816HMeta?.name ?? "users-me-games-id-edit___en",
    path: editKUQzd1816HMeta?.path ?? "games/:id()/edit",
    meta: editKUQzd1816HMeta || {},
    alias: editKUQzd1816HMeta?.alias || [],
    redirect: editKUQzd1816HMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexjiKdk1aCPPMeta?.name ?? "users-me-games-id___en",
    path: indexjiKdk1aCPPMeta?.path ?? "games/:id()",
    meta: indexjiKdk1aCPPMeta || {},
    alias: indexjiKdk1aCPPMeta?.alias || [],
    redirect: indexjiKdk1aCPPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createZsyYnWHfGPMeta?.name ?? "users-me-games-create___en",
    path: createZsyYnWHfGPMeta?.path ?? "games/create",
    meta: createZsyYnWHfGPMeta || {},
    alias: createZsyYnWHfGPMeta?.alias || [],
    redirect: createZsyYnWHfGPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/create.vue").then(m => m.default || m)
  },
  {
    name: indexcF4TtGp9JJMeta?.name ?? "users-me-games___en",
    path: indexcF4TtGp9JJMeta?.path ?? "games",
    meta: indexcF4TtGp9JJMeta || {},
    alias: indexcF4TtGp9JJMeta?.alias || [],
    redirect: indexcF4TtGp9JJMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexHj1J85Qb0xMeta?.name ?? "users-me___en",
    path: indexHj1J85Qb0xMeta?.path ?? "",
    meta: indexHj1J85Qb0xMeta || {},
    alias: indexHj1J85Qb0xMeta?.alias || [],
    redirect: indexHj1J85Qb0xMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsqzSFiq1tkrMeta?.name ?? "users-me-notifications___en",
    path: notificationsqzSFiq1tkrMeta?.path ?? "notifications",
    meta: notificationsqzSFiq1tkrMeta || {},
    alias: notificationsqzSFiq1tkrMeta?.alias || [],
    redirect: notificationsqzSFiq1tkrMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/notifications.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EMHUlzVAZPMeta?.name ?? "users-me-orders-id___en",
    path: _91id_93EMHUlzVAZPMeta?.path ?? "orders/:id()",
    meta: _91id_93EMHUlzVAZPMeta || {},
    alias: _91id_93EMHUlzVAZPMeta?.alias || [],
    redirect: _91id_93EMHUlzVAZPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: index8zdZ3MMsk4Meta?.name ?? "users-me-orders___en",
    path: index8zdZ3MMsk4Meta?.path ?? "orders",
    meta: index8zdZ3MMsk4Meta || {},
    alias: index8zdZ3MMsk4Meta?.alias || [],
    redirect: index8zdZ3MMsk4Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/orders/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45historyMIuOED96zgMeta?.name ?? "users-me-payment-history___en",
    path: payment_45historyMIuOED96zgMeta?.path ?? "payment-history",
    meta: payment_45historyMIuOED96zgMeta || {},
    alias: payment_45historyMIuOED96zgMeta?.alias || [],
    redirect: payment_45historyMIuOED96zgMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/payment-history.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JEGGvy13pJMeta?.name ?? "users-me-tickets-id___en",
    path: _91id_93JEGGvy13pJMeta?.path ?? "tickets/:id()",
    meta: _91id_93JEGGvy13pJMeta || {},
    alias: _91id_93JEGGvy13pJMeta?.alias || [],
    redirect: _91id_93JEGGvy13pJMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/tickets/[id].vue").then(m => m.default || m)
  },
  {
    name: index95nKoleiMZMeta?.name ?? "users-me-tickets___en",
    path: index95nKoleiMZMeta?.path ?? "tickets",
    meta: index95nKoleiMZMeta || {},
    alias: index95nKoleiMZMeta?.alias || [],
    redirect: index95nKoleiMZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/tickets/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: usersjYpGzagbSDMeta?.name ?? "users___ru",
    path: usersjYpGzagbSDMeta?.path ?? "/ru/users",
    meta: usersjYpGzagbSDMeta || {},
    alias: usersjYpGzagbSDMeta?.alias || [],
    redirect: usersjYpGzagbSDMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93OUqbpMOdbNMeta?.name ?? "users-id___ru",
    path: _91id_93OUqbpMOdbNMeta?.path ?? ":id()",
    meta: _91id_93OUqbpMOdbNMeta || {},
    alias: _91id_93OUqbpMOdbNMeta?.alias || [],
    redirect: _91id_93OUqbpMOdbNMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: mevstyywGv64Meta?.name ?? undefined,
    path: mevstyywGv64Meta?.path ?? "me",
    meta: mevstyywGv64Meta || {},
    alias: mevstyywGv64Meta?.alias || [],
    redirect: mevstyywGv64Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me.vue").then(m => m.default || m),
    children: [
  {
    name: basicaE4lV5nEhFMeta?.name ?? "users-me-edit-basic___ru",
    path: basicaE4lV5nEhFMeta?.path ?? "edit/basic",
    meta: basicaE4lV5nEhFMeta || {},
    alias: basicaE4lV5nEhFMeta?.alias || [],
    redirect: basicaE4lV5nEhFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/basic.vue").then(m => m.default || m)
  },
  {
    name: company_45membersmNN7CPxLWiMeta?.name ?? "users-me-edit-company-members___ru",
    path: company_45membersmNN7CPxLWiMeta?.path ?? "edit/company-members",
    meta: company_45membersmNN7CPxLWiMeta || {},
    alias: company_45membersmNN7CPxLWiMeta?.alias || [],
    redirect: company_45membersmNN7CPxLWiMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/company-members.vue").then(m => m.default || m)
  },
  {
    name: settingszMj39xeeQlMeta?.name ?? "users-me-edit-settings___ru",
    path: settingszMj39xeeQlMeta?.path ?? "edit/settings",
    meta: settingszMj39xeeQlMeta || {},
    alias: settingszMj39xeeQlMeta?.alias || [],
    redirect: settingszMj39xeeQlMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/settings.vue").then(m => m.default || m)
  },
  {
    name: favoritesUKR4dhyZzQMeta?.name ?? undefined,
    path: favoritesUKR4dhyZzQMeta?.path ?? "favorites",
    meta: favoritesUKR4dhyZzQMeta || {},
    alias: favoritesUKR4dhyZzQMeta?.alias || [],
    redirect: favoritesUKR4dhyZzQMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites.vue").then(m => m.default || m),
    children: [
  {
    name: companiesAUZiN9xmTWMeta?.name ?? "users-me-favorites-companies___ru",
    path: companiesAUZiN9xmTWMeta?.path ?? "companies",
    meta: companiesAUZiN9xmTWMeta || {},
    alias: companiesAUZiN9xmTWMeta?.alias || [],
    redirect: companiesAUZiN9xmTWMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/companies.vue").then(m => m.default || m)
  },
  {
    name: eventsIHguZlQRmaMeta?.name ?? "users-me-favorites-events___ru",
    path: eventsIHguZlQRmaMeta?.path ?? "events",
    meta: eventsIHguZlQRmaMeta || {},
    alias: eventsIHguZlQRmaMeta?.alias || [],
    redirect: eventsIHguZlQRmaMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/events.vue").then(m => m.default || m)
  },
  {
    name: indexI47ilk6aIyMeta?.name ?? "users-me-favorites___ru",
    path: indexI47ilk6aIyMeta?.path ?? "",
    meta: indexI47ilk6aIyMeta || {},
    alias: indexI47ilk6aIyMeta?.alias || [],
    redirect: indexI47ilk6aIyMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: lecturesJDRRZeNP9uMeta?.name ?? "users-me-favorites-lectures___ru",
    path: lecturesJDRRZeNP9uMeta?.path ?? "lectures",
    meta: lecturesJDRRZeNP9uMeta || {},
    alias: lecturesJDRRZeNP9uMeta?.alias || [],
    redirect: lecturesJDRRZeNP9uMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/lectures.vue").then(m => m.default || m)
  },
  {
    name: newsjJNp6IttrFMeta?.name ?? "users-me-favorites-news___ru",
    path: newsjJNp6IttrFMeta?.path ?? "news",
    meta: newsjJNp6IttrFMeta || {},
    alias: newsjJNp6IttrFMeta?.alias || [],
    redirect: newsjJNp6IttrFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/news.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editKUQzd1816HMeta?.name ?? "users-me-games-id-edit___ru",
    path: editKUQzd1816HMeta?.path ?? "games/:id()/edit",
    meta: editKUQzd1816HMeta || {},
    alias: editKUQzd1816HMeta?.alias || [],
    redirect: editKUQzd1816HMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexjiKdk1aCPPMeta?.name ?? "users-me-games-id___ru",
    path: indexjiKdk1aCPPMeta?.path ?? "games/:id()",
    meta: indexjiKdk1aCPPMeta || {},
    alias: indexjiKdk1aCPPMeta?.alias || [],
    redirect: indexjiKdk1aCPPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createZsyYnWHfGPMeta?.name ?? "users-me-games-create___ru",
    path: createZsyYnWHfGPMeta?.path ?? "games/create",
    meta: createZsyYnWHfGPMeta || {},
    alias: createZsyYnWHfGPMeta?.alias || [],
    redirect: createZsyYnWHfGPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/create.vue").then(m => m.default || m)
  },
  {
    name: indexcF4TtGp9JJMeta?.name ?? "users-me-games___ru",
    path: indexcF4TtGp9JJMeta?.path ?? "games",
    meta: indexcF4TtGp9JJMeta || {},
    alias: indexcF4TtGp9JJMeta?.alias || [],
    redirect: indexcF4TtGp9JJMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexHj1J85Qb0xMeta?.name ?? "users-me___ru",
    path: indexHj1J85Qb0xMeta?.path ?? "",
    meta: indexHj1J85Qb0xMeta || {},
    alias: indexHj1J85Qb0xMeta?.alias || [],
    redirect: indexHj1J85Qb0xMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsqzSFiq1tkrMeta?.name ?? "users-me-notifications___ru",
    path: notificationsqzSFiq1tkrMeta?.path ?? "notifications",
    meta: notificationsqzSFiq1tkrMeta || {},
    alias: notificationsqzSFiq1tkrMeta?.alias || [],
    redirect: notificationsqzSFiq1tkrMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/notifications.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EMHUlzVAZPMeta?.name ?? "users-me-orders-id___ru",
    path: _91id_93EMHUlzVAZPMeta?.path ?? "orders/:id()",
    meta: _91id_93EMHUlzVAZPMeta || {},
    alias: _91id_93EMHUlzVAZPMeta?.alias || [],
    redirect: _91id_93EMHUlzVAZPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: index8zdZ3MMsk4Meta?.name ?? "users-me-orders___ru",
    path: index8zdZ3MMsk4Meta?.path ?? "orders",
    meta: index8zdZ3MMsk4Meta || {},
    alias: index8zdZ3MMsk4Meta?.alias || [],
    redirect: index8zdZ3MMsk4Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/orders/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45historyMIuOED96zgMeta?.name ?? "users-me-payment-history___ru",
    path: payment_45historyMIuOED96zgMeta?.path ?? "payment-history",
    meta: payment_45historyMIuOED96zgMeta || {},
    alias: payment_45historyMIuOED96zgMeta?.alias || [],
    redirect: payment_45historyMIuOED96zgMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/payment-history.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JEGGvy13pJMeta?.name ?? "users-me-tickets-id___ru",
    path: _91id_93JEGGvy13pJMeta?.path ?? "tickets/:id()",
    meta: _91id_93JEGGvy13pJMeta || {},
    alias: _91id_93JEGGvy13pJMeta?.alias || [],
    redirect: _91id_93JEGGvy13pJMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/tickets/[id].vue").then(m => m.default || m)
  },
  {
    name: index95nKoleiMZMeta?.name ?? "users-me-tickets___ru",
    path: index95nKoleiMZMeta?.path ?? "tickets",
    meta: index95nKoleiMZMeta || {},
    alias: index95nKoleiMZMeta?.alias || [],
    redirect: index95nKoleiMZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/tickets/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: usersjYpGzagbSDMeta?.name ?? "users___zh",
    path: usersjYpGzagbSDMeta?.path ?? "/zh/users",
    meta: usersjYpGzagbSDMeta || {},
    alias: usersjYpGzagbSDMeta?.alias || [],
    redirect: usersjYpGzagbSDMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93OUqbpMOdbNMeta?.name ?? "users-id___zh",
    path: _91id_93OUqbpMOdbNMeta?.path ?? ":id()",
    meta: _91id_93OUqbpMOdbNMeta || {},
    alias: _91id_93OUqbpMOdbNMeta?.alias || [],
    redirect: _91id_93OUqbpMOdbNMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: mevstyywGv64Meta?.name ?? undefined,
    path: mevstyywGv64Meta?.path ?? "me",
    meta: mevstyywGv64Meta || {},
    alias: mevstyywGv64Meta?.alias || [],
    redirect: mevstyywGv64Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me.vue").then(m => m.default || m),
    children: [
  {
    name: basicaE4lV5nEhFMeta?.name ?? "users-me-edit-basic___zh",
    path: basicaE4lV5nEhFMeta?.path ?? "edit/basic",
    meta: basicaE4lV5nEhFMeta || {},
    alias: basicaE4lV5nEhFMeta?.alias || [],
    redirect: basicaE4lV5nEhFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/basic.vue").then(m => m.default || m)
  },
  {
    name: company_45membersmNN7CPxLWiMeta?.name ?? "users-me-edit-company-members___zh",
    path: company_45membersmNN7CPxLWiMeta?.path ?? "edit/company-members",
    meta: company_45membersmNN7CPxLWiMeta || {},
    alias: company_45membersmNN7CPxLWiMeta?.alias || [],
    redirect: company_45membersmNN7CPxLWiMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/company-members.vue").then(m => m.default || m)
  },
  {
    name: settingszMj39xeeQlMeta?.name ?? "users-me-edit-settings___zh",
    path: settingszMj39xeeQlMeta?.path ?? "edit/settings",
    meta: settingszMj39xeeQlMeta || {},
    alias: settingszMj39xeeQlMeta?.alias || [],
    redirect: settingszMj39xeeQlMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/edit/settings.vue").then(m => m.default || m)
  },
  {
    name: favoritesUKR4dhyZzQMeta?.name ?? undefined,
    path: favoritesUKR4dhyZzQMeta?.path ?? "favorites",
    meta: favoritesUKR4dhyZzQMeta || {},
    alias: favoritesUKR4dhyZzQMeta?.alias || [],
    redirect: favoritesUKR4dhyZzQMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites.vue").then(m => m.default || m),
    children: [
  {
    name: companiesAUZiN9xmTWMeta?.name ?? "users-me-favorites-companies___zh",
    path: companiesAUZiN9xmTWMeta?.path ?? "companies",
    meta: companiesAUZiN9xmTWMeta || {},
    alias: companiesAUZiN9xmTWMeta?.alias || [],
    redirect: companiesAUZiN9xmTWMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/companies.vue").then(m => m.default || m)
  },
  {
    name: eventsIHguZlQRmaMeta?.name ?? "users-me-favorites-events___zh",
    path: eventsIHguZlQRmaMeta?.path ?? "events",
    meta: eventsIHguZlQRmaMeta || {},
    alias: eventsIHguZlQRmaMeta?.alias || [],
    redirect: eventsIHguZlQRmaMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/events.vue").then(m => m.default || m)
  },
  {
    name: indexI47ilk6aIyMeta?.name ?? "users-me-favorites___zh",
    path: indexI47ilk6aIyMeta?.path ?? "",
    meta: indexI47ilk6aIyMeta || {},
    alias: indexI47ilk6aIyMeta?.alias || [],
    redirect: indexI47ilk6aIyMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: lecturesJDRRZeNP9uMeta?.name ?? "users-me-favorites-lectures___zh",
    path: lecturesJDRRZeNP9uMeta?.path ?? "lectures",
    meta: lecturesJDRRZeNP9uMeta || {},
    alias: lecturesJDRRZeNP9uMeta?.alias || [],
    redirect: lecturesJDRRZeNP9uMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/lectures.vue").then(m => m.default || m)
  },
  {
    name: newsjJNp6IttrFMeta?.name ?? "users-me-favorites-news___zh",
    path: newsjJNp6IttrFMeta?.path ?? "news",
    meta: newsjJNp6IttrFMeta || {},
    alias: newsjJNp6IttrFMeta?.alias || [],
    redirect: newsjJNp6IttrFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/favorites/news.vue").then(m => m.default || m)
  }
]
  },
  {
    name: editKUQzd1816HMeta?.name ?? "users-me-games-id-edit___zh",
    path: editKUQzd1816HMeta?.path ?? "games/:id()/edit",
    meta: editKUQzd1816HMeta || {},
    alias: editKUQzd1816HMeta?.alias || [],
    redirect: editKUQzd1816HMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexjiKdk1aCPPMeta?.name ?? "users-me-games-id___zh",
    path: indexjiKdk1aCPPMeta?.path ?? "games/:id()",
    meta: indexjiKdk1aCPPMeta || {},
    alias: indexjiKdk1aCPPMeta?.alias || [],
    redirect: indexjiKdk1aCPPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createZsyYnWHfGPMeta?.name ?? "users-me-games-create___zh",
    path: createZsyYnWHfGPMeta?.path ?? "games/create",
    meta: createZsyYnWHfGPMeta || {},
    alias: createZsyYnWHfGPMeta?.alias || [],
    redirect: createZsyYnWHfGPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/create.vue").then(m => m.default || m)
  },
  {
    name: indexcF4TtGp9JJMeta?.name ?? "users-me-games___zh",
    path: indexcF4TtGp9JJMeta?.path ?? "games",
    meta: indexcF4TtGp9JJMeta || {},
    alias: indexcF4TtGp9JJMeta?.alias || [],
    redirect: indexcF4TtGp9JJMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/games/index.vue").then(m => m.default || m)
  },
  {
    name: indexHj1J85Qb0xMeta?.name ?? "users-me___zh",
    path: indexHj1J85Qb0xMeta?.path ?? "",
    meta: indexHj1J85Qb0xMeta || {},
    alias: indexHj1J85Qb0xMeta?.alias || [],
    redirect: indexHj1J85Qb0xMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsqzSFiq1tkrMeta?.name ?? "users-me-notifications___zh",
    path: notificationsqzSFiq1tkrMeta?.path ?? "notifications",
    meta: notificationsqzSFiq1tkrMeta || {},
    alias: notificationsqzSFiq1tkrMeta?.alias || [],
    redirect: notificationsqzSFiq1tkrMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/notifications.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EMHUlzVAZPMeta?.name ?? "users-me-orders-id___zh",
    path: _91id_93EMHUlzVAZPMeta?.path ?? "orders/:id()",
    meta: _91id_93EMHUlzVAZPMeta || {},
    alias: _91id_93EMHUlzVAZPMeta?.alias || [],
    redirect: _91id_93EMHUlzVAZPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: index8zdZ3MMsk4Meta?.name ?? "users-me-orders___zh",
    path: index8zdZ3MMsk4Meta?.path ?? "orders",
    meta: index8zdZ3MMsk4Meta || {},
    alias: index8zdZ3MMsk4Meta?.alias || [],
    redirect: index8zdZ3MMsk4Meta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/orders/index.vue").then(m => m.default || m)
  },
  {
    name: payment_45historyMIuOED96zgMeta?.name ?? "users-me-payment-history___zh",
    path: payment_45historyMIuOED96zgMeta?.path ?? "payment-history",
    meta: payment_45historyMIuOED96zgMeta || {},
    alias: payment_45historyMIuOED96zgMeta?.alias || [],
    redirect: payment_45historyMIuOED96zgMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/payment-history.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JEGGvy13pJMeta?.name ?? "users-me-tickets-id___zh",
    path: _91id_93JEGGvy13pJMeta?.path ?? "tickets/:id()",
    meta: _91id_93JEGGvy13pJMeta || {},
    alias: _91id_93JEGGvy13pJMeta?.alias || [],
    redirect: _91id_93JEGGvy13pJMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/tickets/[id].vue").then(m => m.default || m)
  },
  {
    name: index95nKoleiMZMeta?.name ?? "users-me-tickets___zh",
    path: index95nKoleiMZMeta?.path ?? "tickets",
    meta: index95nKoleiMZMeta || {},
    alias: index95nKoleiMZMeta?.alias || [],
    redirect: index95nKoleiMZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/users/me/tickets/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: videoubMXtUehlZMeta?.name ?? undefined,
    path: videoubMXtUehlZMeta?.path ?? "/video",
    meta: videoubMXtUehlZMeta || {},
    alias: videoubMXtUehlZMeta?.alias || [],
    redirect: videoubMXtUehlZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93PTROhxIezuMeta?.name ?? undefined,
    path: _91category_93PTROhxIezuMeta?.path ?? ":category()",
    meta: _91category_93PTROhxIezuMeta || {},
    alias: _91category_93PTROhxIezuMeta?.alias || [],
    redirect: _91category_93PTROhxIezuMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category].vue").then(m => m.default || m),
    children: [
  {
    name: indexpAMPyWudvPMeta?.name ?? "video-category___en",
    path: indexpAMPyWudvPMeta?.path ?? "",
    meta: indexpAMPyWudvPMeta || {},
    alias: indexpAMPyWudvPMeta?.alias || [],
    redirect: indexpAMPyWudvPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_932ihgVr47MyMeta?.name ?? "video-category-item-id___en",
    path: item_45_91id_932ihgVr47MyMeta?.path ?? "item-:id()",
    meta: item_45_91id_932ihgVr47MyMeta || {},
    alias: item_45_91id_932ihgVr47MyMeta?.alias || [],
    redirect: item_45_91id_932ihgVr47MyMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: populartibytBoylVMeta?.name ?? "video-category-popular___en",
    path: populartibytBoylVMeta?.path ?? "popular",
    meta: populartibytBoylVMeta || {},
    alias: populartibytBoylVMeta?.alias || [],
    redirect: populartibytBoylVMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexuOQ2Q9c4iaMeta?.name ?? "video___en",
    path: indexuOQ2Q9c4iaMeta?.path ?? "",
    meta: indexuOQ2Q9c4iaMeta || {},
    alias: indexuOQ2Q9c4iaMeta?.alias || [],
    redirect: indexuOQ2Q9c4iaMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/index.vue").then(m => m.default || m)
  },
  {
    name: popularFTMxzZXccFMeta?.name ?? "video-popular___en",
    path: popularFTMxzZXccFMeta?.path ?? "popular",
    meta: popularFTMxzZXccFMeta || {},
    alias: popularFTMxzZXccFMeta?.alias || [],
    redirect: popularFTMxzZXccFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: videoubMXtUehlZMeta?.name ?? undefined,
    path: videoubMXtUehlZMeta?.path ?? "/ru/video",
    meta: videoubMXtUehlZMeta || {},
    alias: videoubMXtUehlZMeta?.alias || [],
    redirect: videoubMXtUehlZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93PTROhxIezuMeta?.name ?? undefined,
    path: _91category_93PTROhxIezuMeta?.path ?? ":category()",
    meta: _91category_93PTROhxIezuMeta || {},
    alias: _91category_93PTROhxIezuMeta?.alias || [],
    redirect: _91category_93PTROhxIezuMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category].vue").then(m => m.default || m),
    children: [
  {
    name: indexpAMPyWudvPMeta?.name ?? "video-category___ru",
    path: indexpAMPyWudvPMeta?.path ?? "",
    meta: indexpAMPyWudvPMeta || {},
    alias: indexpAMPyWudvPMeta?.alias || [],
    redirect: indexpAMPyWudvPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_932ihgVr47MyMeta?.name ?? "video-category-item-id___ru",
    path: item_45_91id_932ihgVr47MyMeta?.path ?? "item-:id()",
    meta: item_45_91id_932ihgVr47MyMeta || {},
    alias: item_45_91id_932ihgVr47MyMeta?.alias || [],
    redirect: item_45_91id_932ihgVr47MyMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: populartibytBoylVMeta?.name ?? "video-category-popular___ru",
    path: populartibytBoylVMeta?.path ?? "popular",
    meta: populartibytBoylVMeta || {},
    alias: populartibytBoylVMeta?.alias || [],
    redirect: populartibytBoylVMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexuOQ2Q9c4iaMeta?.name ?? "video___ru",
    path: indexuOQ2Q9c4iaMeta?.path ?? "",
    meta: indexuOQ2Q9c4iaMeta || {},
    alias: indexuOQ2Q9c4iaMeta?.alias || [],
    redirect: indexuOQ2Q9c4iaMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/index.vue").then(m => m.default || m)
  },
  {
    name: popularFTMxzZXccFMeta?.name ?? "video-popular___ru",
    path: popularFTMxzZXccFMeta?.path ?? "popular",
    meta: popularFTMxzZXccFMeta || {},
    alias: popularFTMxzZXccFMeta?.alias || [],
    redirect: popularFTMxzZXccFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: videoubMXtUehlZMeta?.name ?? undefined,
    path: videoubMXtUehlZMeta?.path ?? "/zh/video",
    meta: videoubMXtUehlZMeta || {},
    alias: videoubMXtUehlZMeta?.alias || [],
    redirect: videoubMXtUehlZMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video.vue").then(m => m.default || m),
    children: [
  {
    name: _91category_93PTROhxIezuMeta?.name ?? undefined,
    path: _91category_93PTROhxIezuMeta?.path ?? ":category()",
    meta: _91category_93PTROhxIezuMeta || {},
    alias: _91category_93PTROhxIezuMeta?.alias || [],
    redirect: _91category_93PTROhxIezuMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category].vue").then(m => m.default || m),
    children: [
  {
    name: indexpAMPyWudvPMeta?.name ?? "video-category___zh",
    path: indexpAMPyWudvPMeta?.path ?? "",
    meta: indexpAMPyWudvPMeta || {},
    alias: indexpAMPyWudvPMeta?.alias || [],
    redirect: indexpAMPyWudvPMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: item_45_91id_932ihgVr47MyMeta?.name ?? "video-category-item-id___zh",
    path: item_45_91id_932ihgVr47MyMeta?.path ?? "item-:id()",
    meta: item_45_91id_932ihgVr47MyMeta || {},
    alias: item_45_91id_932ihgVr47MyMeta?.alias || [],
    redirect: item_45_91id_932ihgVr47MyMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/item-[id].vue").then(m => m.default || m)
  },
  {
    name: populartibytBoylVMeta?.name ?? "video-category-popular___zh",
    path: populartibytBoylVMeta?.path ?? "popular",
    meta: populartibytBoylVMeta || {},
    alias: populartibytBoylVMeta?.alias || [],
    redirect: populartibytBoylVMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/[category]/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexuOQ2Q9c4iaMeta?.name ?? "video___zh",
    path: indexuOQ2Q9c4iaMeta?.path ?? "",
    meta: indexuOQ2Q9c4iaMeta || {},
    alias: indexuOQ2Q9c4iaMeta?.alias || [],
    redirect: indexuOQ2Q9c4iaMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/index.vue").then(m => m.default || m)
  },
  {
    name: popularFTMxzZXccFMeta?.name ?? "video-popular___zh",
    path: popularFTMxzZXccFMeta?.path ?? "popular",
    meta: popularFTMxzZXccFMeta || {},
    alias: popularFTMxzZXccFMeta?.alias || [],
    redirect: popularFTMxzZXccFMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/video/popular.vue").then(m => m.default || m)
  }
]
  },
  {
    name: wn_45forbes_45magazineQlC5UbxelAMeta?.name ?? "wn-forbes-magazine___en",
    path: wn_45forbes_45magazineQlC5UbxelAMeta?.path ?? "/wn-forbes-magazine",
    meta: wn_45forbes_45magazineQlC5UbxelAMeta || {},
    alias: wn_45forbes_45magazineQlC5UbxelAMeta?.alias || [],
    redirect: wn_45forbes_45magazineQlC5UbxelAMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/wn-forbes-magazine.vue").then(m => m.default || m)
  },
  {
    name: wn_45forbes_45magazineQlC5UbxelAMeta?.name ?? "wn-forbes-magazine___ru",
    path: wn_45forbes_45magazineQlC5UbxelAMeta?.path ?? "/ru/wn-forbes-magazine",
    meta: wn_45forbes_45magazineQlC5UbxelAMeta || {},
    alias: wn_45forbes_45magazineQlC5UbxelAMeta?.alias || [],
    redirect: wn_45forbes_45magazineQlC5UbxelAMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/wn-forbes-magazine.vue").then(m => m.default || m)
  },
  {
    name: wn_45forbes_45magazineQlC5UbxelAMeta?.name ?? "wn-forbes-magazine___zh",
    path: wn_45forbes_45magazineQlC5UbxelAMeta?.path ?? "/zh/wn-forbes-magazine",
    meta: wn_45forbes_45magazineQlC5UbxelAMeta || {},
    alias: wn_45forbes_45magazineQlC5UbxelAMeta?.alias || [],
    redirect: wn_45forbes_45magazineQlC5UbxelAMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/wn-forbes-magazine.vue").then(m => m.default || m)
  },
  {
    name: wn_45game_45publishingebXDBak5WbMeta?.name ?? "wn-game-publishing___en",
    path: wn_45game_45publishingebXDBak5WbMeta?.path ?? "/wn-game-publishing",
    meta: wn_45game_45publishingebXDBak5WbMeta || {},
    alias: wn_45game_45publishingebXDBak5WbMeta?.alias || [],
    redirect: wn_45game_45publishingebXDBak5WbMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/wn-game-publishing.vue").then(m => m.default || m)
  },
  {
    name: wn_45game_45publishingebXDBak5WbMeta?.name ?? "wn-game-publishing___ru",
    path: wn_45game_45publishingebXDBak5WbMeta?.path ?? "/ru/wn-game-publishing",
    meta: wn_45game_45publishingebXDBak5WbMeta || {},
    alias: wn_45game_45publishingebXDBak5WbMeta?.alias || [],
    redirect: wn_45game_45publishingebXDBak5WbMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/wn-game-publishing.vue").then(m => m.default || m)
  },
  {
    name: wn_45game_45publishingebXDBak5WbMeta?.name ?? "wn-game-publishing___zh",
    path: wn_45game_45publishingebXDBak5WbMeta?.path ?? "/zh/wn-game-publishing",
    meta: wn_45game_45publishingebXDBak5WbMeta || {},
    alias: wn_45game_45publishingebXDBak5WbMeta?.alias || [],
    redirect: wn_45game_45publishingebXDBak5WbMeta?.redirect,
    component: () => import("/builds/sites/wnhub-general-client/pages/wn-game-publishing.vue").then(m => m.default || m)
  }
]