<template>
  <BaseModal
    :is-open="isOpen"
    :title="popupTitle || $t('tickets.about_ticket')"
    size="lg"
    @close="emit('close')"
  >
    <div class="flex flex-col gap-6">
      <div class="marked" v-html="getMarkedTextWithBlankLinks(text)" />
      <div class="ml-auto">
        <BaseButton @click="emit('close')">
          {{ $t('common.ok') }}
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { YandexECommerceEventType } from '~/models/ecommerce-yandex'
import { useOrders } from '~/stores/orders'
import { useEvents } from '~/stores/events'

interface PropsInterface {
  text: string
  ticketId: number | null
  isOpen: boolean
  ticketCount?: number
  popupTitle?: string
}

const props = defineProps<PropsInterface>()

const emit = defineEmits(['close'])

const { getMarkedTextWithBlankLinks } = useMarkedText()
const {
  $yandexMetrika: {
    sendYandexECommerceEvent,
    getYandexECommerceProductFromTicket,
  },
  $gtm: { getGoogleECommerceItemFromTicket, sendViewItemGoogleECommerceEvent },
} = useNuxtApp()
const ordersStore = useOrders()
const eventsStore = useEvents()

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) {
      sendYandexECommerceDetailEvent()
    }
  },
  { immediate: true }
)

function sendYandexECommerceDetailEvent(): void {
  const ticket = props.ticketId && ordersStore.ticketsDetailsMap[props.ticketId]
  const currency = eventsStore.currency

  if (!ticket) {
    return
  }

  sendYandexECommerceEvent({
    currencyCode: currency,
    [YandexECommerceEventType.Detail]: {
      products: [
        getYandexECommerceProductFromTicket(
          ticket,
          currency,
          eventsStore.event,
          props.ticketCount || 1
        ),
      ],
    },
  })

  sendViewItemGoogleECommerceEvent(currency, [
    getGoogleECommerceItemFromTicket(
      ticket,
      currency,
      eventsStore.event,
      props.ticketCount || 1
    ),
  ])
}
</script>
