import {
  DEFAULT_COLOR_PRIMARY,
  DEFAULT_MARKDOWN_PLACEHOLDER,
  DEFAULT_COLOR_WHITE,
} from '../constants'
import { basePropertiesSchema } from '../base-schema-properties'
import {
  bgColorSchema,
  showAllSettingsSchema,
  titlePropertiesSchema,
  textColorSchema,
  textFontFamilySchema,
  buttonsPropertiesSchema,
  linkColorSchema,
} from '../common'

export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  title: 'Packages',
  description: 'Packages',
  type: 'object',
  'ui:options': {
    onlyShowIfDependent: true,
  },
  dependencies: {
    tmp_showAllSettings: ['bgColor', 'textColor'],
    title: ['titleAlign', 'hideOnEmbed'],
  },
  properties: {
    ...basePropertiesSchema,
    ...titlePropertiesSchema,
    subtitle: {
      title: 'Subtitle',
      type: 'string',
    },
    ...showAllSettingsSchema,
    ...bgColorSchema,
    subtitleAlign: {
      ...titlePropertiesSchema.titleAlign,
      title: 'Subtitle align',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleWeight: {
      ...titlePropertiesSchema.titleWeight,
      title: 'Subtitle weight',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleStyle: {
      ...titlePropertiesSchema.titleStyle,
      title: 'Subtitle style',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleSizePx: {
      ...titlePropertiesSchema.titleSizePx,
      title: 'Subtitle size',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleColor: {
      ...titlePropertiesSchema.titleColor,
      title: 'Subtitle color',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    subtitleFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      title: 'Subtitle font family',
      'ui:hidden': '{{parentFormData.tmp_showAllSettings === undefined}}',
    },
    categories: {
      type: 'array',
      title: 'A list of category cards',
      'ui:options': {
        showIndexNumber: true,
      },
      items: {
        title: 'Category card',
        type: 'object',
        required: ['title'],
        properties: {
          title: {
            title: 'Title',
            type: 'string',
            default: 'Category title',
          },
          color: {
            title: 'Card color',
            type: 'string',
            default: DEFAULT_COLOR_PRIMARY,
          },
          titleColor: {
            ...textColorSchema.textColor,
            title: 'Card title color',
            default: DEFAULT_COLOR_WHITE,
          },
          titleFontFamily: {
            ...textFontFamilySchema.textFontFamily,
            title: 'Card title font family',
          },
          textColor: {
            ...textColorSchema.textColor,
            title: 'Card text color',
          },
          textFontFamily: {
            ...textFontFamilySchema.textFontFamily,
            title: 'Card text font family',
          },
          isShowVat: {
            title: 'Is show VAT',
            type: 'boolean',
            default: true,
          },
          text: {
            title: 'Text',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
              type: 'textarea',
              rows: 6,
            },
          },
          aboutPackageText: {
            title: 'About package text',
            type: 'string',
            default: 'about package',
          },
          popupTitle: {
            title: 'Popup title',
            type: 'string',
            default: 'About package',
          },
          additionalInfo: {
            title: 'Additional info for popup',
            type: 'string',
            'ui:options': {
              placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
              type: 'textarea',
              rows: 6,
            },
          },
          packages: {
            type: 'array',
            title: 'Linked packages',
            'ui:options': {
              showIndexNumber: true,
            },
            items: {
              title: 'Package',
              type: 'object',
              required: ['label'],
              properties: {
                label: {
                  title: 'Name',
                  type: 'string',
                },
                value: {
                  title: 'Package id',
                  type: 'number',
                },
                maxPackagesCount: {
                  title: 'Max packages count',
                  type: 'number',
                  default: 99,
                  minimum: 1,
                  maximum: 99,
                },
                freePackageText: {
                  title: 'Free package price text',
                  type: 'string',
                  default: 'Free',
                },
              },
            },
          },
        },
      },
    },
    footerText: {
      title: 'Footer text',
      type: 'string',
      'ui:options': {
        placeholder: DEFAULT_MARKDOWN_PLACEHOLDER,
        type: 'textarea',
        rows: 6,
      },
      'ui:widget': 'EditorMarkdownWidget',
    },
    footerTextColor: {
      ...textColorSchema.textColor,
      title: 'Footer text color',
      default: undefined,
    },
    footerTextLinkColor: {
      ...linkColorSchema.linkColor,
      title: 'Footer text link color',
    },
    footerTextFontFamily: {
      ...textFontFamilySchema.textFontFamily,
      title: 'Footer text font family',
    },
    footerButtons: {
      ...buttonsPropertiesSchema.buttons,
      title: 'Footer buttons',
    },
  },
}
